import { baseUrl } from '../../common/ApiEndPoint';

const rolesBaseUrl = `${baseUrl}/ref-data`;

export const getAllStatesUrl = `${rolesBaseUrl}/states`;

export const getAllTaskTypesUrl = `${baseUrl}/ref-data/task-types`;

export const getAllTaskStatusesUrl = `${baseUrl}/ref-data/task-status`;

export const getAllFlagReasonsUrl = `${baseUrl}/ref-data/flag-reasons`;

export const getAllStatusReasonsUrl = `${baseUrl}/ref-data/unclassified-reasons`;
