import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { APP } from 'src/styles/variables';

type ButtonStyledType = {
  disabled?: boolean;
};

export const ButtonStyled = styled(Button)<ButtonStyledType>`
  width: 5.866rem;
  height: 2.5rem;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  background-color: ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.BLACK};
  text-align: center;
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  text-transform: uppercase;

  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const RowPauseStatusDetailsStyleDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const RowStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const TypeCellSubTextStyle = styled('p')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const ReprocessButtonStyled = styled(ButtonStyled)`
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const InProgressButtonStyled = styled(ButtonStyled)`
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  width: max-content;
  min-width: min-content;
`;

export const AutomationPauseButtonStyled = styled(ButtonStyled)`
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 32px;
`;

export const NoDataFoundDivStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

export const OverlayLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${APP.PALETTE.BACKGROUND.WHITE_WITH_OPACITY};
  z-index: 10000;
`;

export const OverlayLoaderContainerForTaskTypeAutomation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${APP.PALETTE.BACKGROUND.BLACK_WITH_OPACITY};
  z-index: 10000;
`;

export const LastReprocessedDetailsStyleDiv = styled('div')`
  display: flex;
`;

export const ReprocessValuesStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.BODY2};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const ReprocessedAtValuesStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;

export const PausedChangedAtDetailsStyleDiv = styled('div')`
  display: flex;
`;

export const PausedChangedAtValuesStyleDiv = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  margin-top: 0.5rem;
`;
