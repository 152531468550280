import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { APP } from 'src/styles/variables';

import styled from '@emotion/styled';

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 8;
  top: 8;
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
`;

interface TitleStyledProps {
  titleFontSize?: string;
}

export const TitleStyled = styled('div')<TitleStyledProps>`
  color: ${APP.PALETTE.COMMON.BLACK};
  font-size: ${props =>
    props.titleFontSize ? props.titleFontSize : APP.FONT.FONT_SIZE.H4};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin-bottom: 0.5rem;
`;

export const SubTitleStyled = styled('div')`
  color: ${props =>
    props.color ? props.color : APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
  margin-bottom: 1rem;
`;

export const ButtonStyled = styled(Button)<{ disabled?: boolean }>`
  text-transform: capitalize;
  border-radius: 10px;
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin-bottom: 0.5rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SaveButtonStyled = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${APP.PALETTE.GREY.DISABLE_GREY};
  }
`;

export const SaveButtonDisabledStyled = styled(SaveButtonStyled)<{
  disabled?: boolean;
}>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${APP.PALETTE.GREY.DISABLE_GREY};
  }
`;

export const CancelButtonStyled = styled(ButtonStyled)`
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  color: ${APP.PALETTE.COMMON.BLACK};
  border: 0.5px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  }
`;

export const DialogActionsContainer = styled(DialogActions)`
  justify-content: center;
`;

export const DialogContentStyled = styled(DialogContent)`
  padding-bottom: 0;
`;
