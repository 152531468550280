import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { APP } from 'src/styles/variables';
import { useState } from 'react';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import Loader from '@components/loaders/Loader';
import { produce } from 'immer';
import { updateUnflagTask } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import {
  CancelButtonWidthStyled,
  DialogActionsContainerStyled,
  SaveButtonWidthStyled,
} from './UnFlagQueueItemStyle';
interface IUnflagQueueItemProps {
  handleCancel: () => void;
  isDialogOpen: boolean;
  taskId?: string;
  handleSave?: () => void;
  refreshScreen?: (refreshQueueTableGrid: boolean) => void;
}
interface IUnflagQueueItemState {
  isSubmitLoader: boolean;
}
const UnFlagQueueItem = (props: IUnflagQueueItemProps): JSX.Element => {
  const { reset } = useForm({});
  const {
    isDialogOpen: openDialog,
    handleCancel,
    taskId,
    handleSave,
    refreshScreen,
  } = props;
  const [unflagQueueItemState, setUnflagQueueItemState] =
    useState<IUnflagQueueItemState>({
      isSubmitLoader: false,
    });
  const { isSubmitLoader } = unflagQueueItemState;
  const onClose = () => {
    reset();
    handleCancel();
  };
  const onSubmit = async () => {
    try {
      if (taskId) {
        setUnflagQueueItemState(
          produce(draft => {
            draft.isSubmitLoader = true;
          }),
        );
        const res = await updateUnflagTask(taskId);
        if (res) {
          enqueueSnackbar(res.data?.message, SnackBarConfig.getSuccess());
          setUnflagQueueItemState(
            produce(draft => {
              draft.isSubmitLoader = false;
            }),
          );
          onClose();
          handleSave?.();
          refreshScreen?.(true);
        }
      } else {
        enqueueSnackbar(
          t('USER.ERROR_MESSAGE.UNABLE_TO_FIND_TASK_ID'),
          SnackBarConfig.getError(),
        );
        return;
      }
    } catch (e) {
      setUnflagQueueItemState(
        produce(draft => {
          draft.isSubmitLoader = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };
  const renderFooterSection = () => {
    return (
      <DialogActionsContainerStyled>
        <CancelButtonWidthStyled onClick={onClose} disabled={isSubmitLoader}>
          {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.CANCEL')}
        </CancelButtonWidthStyled>
        <SaveButtonWidthStyled
          type="submit"
          onClick={onSubmit}
          disabled={isSubmitLoader}
        >
          {isSubmitLoader ? (
            <Loader size={32} />
          ) : (
            t('ADMIN.QUEUE_TABS.UN_FLAG_QUEUE_ITEM.UN_FLAG')
          )}
        </SaveButtonWidthStyled>
      </DialogActionsContainerStyled>
    );
  };
  return (
    <FormDialog
      width="34.25rem" // 548px
      isDialogOpen={openDialog}
      handleCancel={onClose}
      titleText={t('ADMIN.QUEUE_TABS.UN_FLAG_QUEUE_ITEM.UN_FLAG_QUEUE_ITEM')}
      subTitleText={t(
        'ADMIN.QUEUE_TABS.UN_FLAG_QUEUE_ITEM.UN_FLAG_QUEUE_ITEM_DESCRIPTION',
      )}
      subTitleTextColor={APP.PALETTE.COMMON.BLACK}
      isSaveInProgress={isSubmitLoader}
      dialogContent={''}
      submitButtonText={''}
      cancelButtonText={''}
      onSubmit={onSubmit}
      renderFooterButtonSection={renderFooterSection()}
    />
  );
};
export default UnFlagQueueItem;
