import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { APP } from 'src/styles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const ButtonWrapperStyled = styled.button<{ disabled?: boolean }>`
  padding: 0.8rem;
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${({ disabled = false }) =>
    disabled
      ? `${APP.PALETTE.GREY.SHADE_A400}`
      : `${APP.PALETTE.COMMON.WHITE}`};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  border: none;
  border-radius: 10px;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    box-shadow: ${({ disabled = false }) =>
      disabled ? '' : `${APP.PALETTE.GREY.BOX_SHADOW.GRAY}`};
  }
`;

export const HeaderStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  margin: 1rem 0;
`;

export const LogoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
`;

export const StyledPaper = styled(Paper)`
  width: 31rem;
  padding: 2rem;
  flex-shrink: 0;
`;

export const ContainerDivStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LabelDivStyled = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
  padding-bottom: 0.2rem;
`;

export const PasswordContainerStyled = styled('div')`
  gap: 1rem;
  margin: 1rem 0 1.5rem 0;
`;
