import ServiceRequest from 'src/services/ServiceRequest';
import {
  getDBQueryAndDataExceptionsMonitoringUrl,
  getEmailsMonitoringUrl,
} from './MonitoringApiEndPoints';
import {
  DBQueryAndDataExceptionsParams,
  EmailsMonitoringParams,
  GetDBQueryAndDataExceptionsMonitoringResDto,
  GetEmailsMonitoringResDto,
} from './MonitoringServiceMapper';
import { CancelTokenSource } from 'axios';

export const getDBQueryAndDataExceptionsMonitoring = async (
  data: DBQueryAndDataExceptionsParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetDBQueryAndDataExceptionsMonitoringResDto> => {
  return await ServiceRequest.get(
    getDBQueryAndDataExceptionsMonitoringUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getEmailsMonitoring = async (
  data: EmailsMonitoringParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetEmailsMonitoringResDto> => {
  return await ServiceRequest.get(
    getEmailsMonitoringUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};
