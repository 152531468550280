import TextButton from '@components/buttons/text-button/TextButton';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const TopBarGrid = styled(Grid)`
  width: 100%;
  height: 3.2rem;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const TopBarTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const SectionDividerHR = styled('hr')`
  width: 100%;
  border: 1px solid ${APP.PALETTE.GREY.SHADE_300};
`;

export const DropdownInputWrapperGrid = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.9rem;
  padding: 0.5rem 0 0.8rem 0;
`;

export const ActionButtonWrapperGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  padding-top: 0.6rem;
`;

export const DropDownInputGrid = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DropdownLabelTypography = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY2};
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const SaveTextButton = styled(TextButton)`
  width: 100%;
  border-radius: 0.625rem;
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  color: ${APP.PALETTE.COMMON.WHITE};
  &:hover {
    border-radius: 0.625rem;
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;

export const CancelTextButton = styled(TextButton)`
  width: 100%;
  border-radius: 0.625rem;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  color: ${APP.PALETTE.TEXT.PRIMARY};
  &:hover {
    border-radius: 0.625rem;
    color: ${APP.PALETTE.TEXT.PRIMARY};
    background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  }
`;
