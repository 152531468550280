import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from '../modules/home/state/auth/AuthStateSlice';
import profileDetailsReducer from '../modules/home/state/profile-details/ProfileDetailsSlice';
import queueReducer from '../modules/home/state/queue/QueueSlice';
import usersReducer from '@modules/home/state/users/UsersSlice';
import emailReducer from '@modules/home/state/email/EmailSlice';
import emailSettingsReducer from '@modules/home/state/email-settings/EmailSettingsSlice';
import automationSettingsReducer from '@modules/home/state/automation-settings/AutomationSettingsSlice';
import DbMonitoringReducer from '@modules/home/state/db-monitoring/DbMonitoringSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileDetailsReducer,
  queue: queueReducer,
  users: usersReducer,
  email: emailReducer,
  emailSettings: emailSettingsReducer,
  automationSettings: automationSettingsReducer,
  dbMonitoring: DbMonitoringReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
