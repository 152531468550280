import ServiceRequest from 'src/services/ServiceRequest';
import { DataExceptionsLoadUrl } from './DataExceptionsApiEndPoints';
import { updateDataExceptionsLoadResponseData } from './DataExceptionsServiceMapper';

export const updateDataExceptionsLoad =
  async (): Promise<updateDataExceptionsLoadResponseData> => {
    return await ServiceRequest.post(DataExceptionsLoadUrl, {
      payload: {},
    });
  };
