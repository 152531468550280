import TextButton from '@components/buttons/text-button/TextButton';
import SearchBar from '@components/search-bar/SearchBar';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import { ReactComponent as SearchIcon } from '@images/Search_Black.svg';
import { ReactComponent as ClearIcon } from '@images/X.svg';
import theme from 'src/styles/theme';
import { APP } from 'src/styles/variables';

export const QueueMainContainer = styled.div``;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  margin-left: 1.875rem;
  height: 100%;
`;

export const FilterIconButton = styled(IconButton)`
  padding: 0.125rem;
  cursor: pointer;
`;

export const TabItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props =>
    props.isActive
      ? props.theme.palette.common.black
      : APP.PALETTE.GREY.TERTIARY_COLOR};
  border-bottom: ${props =>
    props.isActive ? `6px solid ${APP.PALETTE.SECONDARY.MAIN}` : 'none'};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: 1rem;
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};

  &:focus-visible {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:active {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:hover {
    outline: none;
    /* border-bottom: ${`6px solid ${theme.palette.secondary.main}`}; */
    color: ${theme.palette.common.black};
    background-color: ${props =>
      props.isActive ? 'none' : APP.PALETTE.GREY.LIGHT_GREY};
  }

  position: relative;
`;

export const TabItemText = styled.div<{ isActive: boolean }>`
  position: relative;
  top: ${props => (props.isActive ? `3px` : '0')};
`;

export const QueueTabBodyContainer = styled.div``;

// Top Bar Right Menu

export const RightMenuOptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchIconContainer = styled.div`
  margin-right: 1.875rem;
`;

export const SearchBarStyled = styled(SearchBar)`
  width: 21rem;
  @media screen and (max-width: 768px) {
    width: 17rem;
  }
  margin-right: 1.375rem;
`;

export const FilterMenuContainer = styled.div`
  padding-right: 0.9375rem;
`;

export const CustomSearchIcon = styled(SearchIcon)`
  cursor: pointer;
  &:focus {
    outline: none;
    border: none;
  }
  margin-right: 1.875rem;
`;

export const BulkEditTextButton = styled(TextButton)`
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  margin-right: 1.25rem;

  &:hover {
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
    border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;

// search bar with expandable width

export const SearchFieldContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBoxContainer = styled('div')<{ searchIconClicked: boolean }>`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SearchInput = styled('input')`
  height: 3.25rem;
  font-size: 1.125rem;
  margin-right: 0.625rem;
  width: ${(props: { searchIconClicked?: boolean }) =>
    props.searchIconClicked ? '12.5rem' : '0'};
  transition: width 500ms;
  padding-left: ${(props: { searchIconClicked?: boolean }) =>
    props.searchIconClicked ? '0.625rem' : '0'};
  padding-right: ${(props: { searchIconClicked?: boolean }) =>
    props.searchIconClicked ? '2.8125rem' : '0'};
  border: ${({ searchIconClicked }) => (searchIconClicked ? 'revert' : 'none')};

  &:focus-visible {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

export const CustomClearIconContainer = styled('div')`
  display: ${(props: {
    searchTextCount?: boolean;
    searchIconClicked?: boolean;
  }) =>
    props.searchTextCount && props.searchIconClicked ? 'inherit' : 'none'};
  position: absolute;
`;

export const CustomClearIcon = styled(ClearIcon)`
  cursor: pointer;
  width: 1.25rem;
  margin-right: 1.5625rem;

  &:focus {
    outline: none;
    border: none;
  }
`;

export const TabFilterWrapper = styled('div')`
  padding-right: 1.5rem;
`;

export const BulkEditTextButtonForUnClassifiedTab = styled('div')`
  display: flex;
`;
