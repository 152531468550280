import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const EmailDetailsWrapperGrid = styled(Grid)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const EmailDetailsHeadingSectionGrid = styled(Grid)`
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopBarContentGrid = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 1.875rem 1.25rem;
`;

export const NavigationTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-weight: 700;
  text-decoration-line: underline;
  color: ${APP.FONT.FONT_COLOR.TERTIARY};
  cursor: pointer;
`;

export const EmailDetailsContentSectionGrid = styled(Grid)`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
`;

export const EmailViewerSectionGrid = styled(Grid)`
  width: 70%;
  height: 100%;
  overflow: auto;
`;

export const TaskDetailsSectionGrid = styled(Grid)`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};
  padding: 1.875rem;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    padding: 1.875rem 0.5rem;
  }
`;

export const TaskDetailsHeaderGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.625rem;
`;

export const TaskDetailsHeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.H6};
  font-weight: 700;
`;

export const TaskDetailContentWrapperGrid = styled(Grid)`
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 0.625rem;
  overflow: auto;
  margin-top: 1.25rem;
`;

export const TaskDetailContentGrid = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0.937rem;
  margin-bottom: 1.375rem;

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
    margin-bottom: 0.375rem;
  }
`;

export const TaskDetailTypographyLabel = styled(Typography)`
  width: 20%;
  height: 100%;
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: 0.937rem;
  font-weight: 700;

  @media only screen and (max-width: 1400px) {
    word-break: break-all;
  }
`;

export const TaskDetailTypographyValue = styled(Typography)`
  flex: 1;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: 700;
`;
export const ActivityLogMessageWrapperGrid = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.937rem;
`;

export const ActivityLogHeaderGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.312rem;
  flex-wrap: wrap;
`;

export const ActivityLogNameTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-weight: 700;
`;

export const ActivityLogDateTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-weight: 500;
`;

export const ActivityLogContentGrid = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_16PX};
  font-weight: 500;
  line-height: 1.375rem;
`;

export const HorizontalDivider = styled('hr')`
  height: 0.0625rem;
  width: 100%;
  border-bottom: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
  margin-top: 1.25rem;
  margin-bottom: 0.312rem;
`;

export const TaskDetailsLoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
`;

export const TaskDetailsNoDataGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
`;

export const TaskDetailsNoDataMessageTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A700};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: 600;
`;

export const EmailDetailsLoadingGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.25rem;
`;

export const EmailDetailsNoDataGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6.25rem;
`;

export const EmailDetailsTypography = styled(Typography)`
  color: ${APP.PALETTE.GREY.SHADE_A400};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
  padding-top: 10px;
`;
