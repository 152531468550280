import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import { APP } from 'src/styles/variables';

const Loader = (props: CircularProgressProps): React.ReactElement => {
  return (
    <CircularProgress
      sx={{
        color: props.color ? props.color : `${APP.PALETTE.SECONDARY.MAIN}`,
      }}
      {...props}
    />
  );
};
export default Loader;
