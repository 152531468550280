import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DbMonitoringAndDataExceptionsStateType,
  DBQueryFilterPayloadType,
  EmailFilterPayloadType,
  IsDbQueryAndDataExceptionsDataLoadingPayloadType,
  IsEmailsDataLoadingPayloadType,
  isReloadingInProgressPayloadType,
  SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType,
  SetIsEmailsFilterButtonClickedPayloadType,
} from './DbMonitoringTypes';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

const initialState: DbMonitoringAndDataExceptionsStateType = {
  DbMonitoringAndDataExceptionsTableName:
    'DB-Query-And-DB-Exceptions-Monitoring-Table',
  EmailMonitoringTableName: 'Email-Monitoring-Table',
  isDbQueryAndDataExceptionsFilterButtonClicked: false,
  isEmailsFilterButtonClicked: false,
  dbQueryFilterOptions: {},
  emailFilterOptions: {},
  isDbQueryAndDataExceptionsDataLoading: false,
  isEmailsDataLoading: false,
  updatedReloadData: {},
  rowIdsForReloadData: [],
  isReLoadingInProgress: {},
};

const DbMonitoringSlice = createSlice({
  initialState,
  name: 'DbMonitoring',
  reducers: {
    changeDbQueryAndDataExceptionsTableName(state) {
      state.DbMonitoringAndDataExceptionsTableName = generateRandomTableName(
        AdminConstants.EMAIL_SETTINGS.EMAIL_SETTING_DETAILS.MONITORING
          .DB_QUERY_AND_DB_EXCEPTION_TABLE,
      );
    },
    changeEmailMonitoringTableName(state) {
      state.EmailMonitoringTableName = generateRandomTableName(
        AdminConstants.EMAIL_SETTINGS.EMAIL_SETTING_DETAILS.MONITORING
          .EMAIL_MONITORING_TABLE,
      );
    },
    setIsDbQueryAndDataExceptionsFilterButtonClicked(
      state,
      action: PayloadAction<SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType>,
    ) {
      state.isDbQueryAndDataExceptionsFilterButtonClicked =
        action.payload.isDbQueryAndDataExceptionsFilterButtonClickedPayload;
    },
    setDBQueryFilterValues(
      state,
      action: PayloadAction<DBQueryFilterPayloadType>,
    ) {
      state.dbQueryFilterOptions = action.payload.data;
    },
    setIsEmailsFilterButtonClicked(
      state,
      action: PayloadAction<SetIsEmailsFilterButtonClickedPayloadType>,
    ) {
      state.isEmailsFilterButtonClicked =
        action.payload.isEmailsFilterButtonClickedPayload;
    },
    setEmailFilterValues(state, action: PayloadAction<EmailFilterPayloadType>) {
      state.emailFilterOptions = action.payload.data;
    },
    setIsDbQueryAndDataExceptionsDataLoading(
      state,
      action: PayloadAction<IsDbQueryAndDataExceptionsDataLoadingPayloadType>,
    ) {
      state.isDbQueryAndDataExceptionsDataLoading =
        action.payload.isDbQueryAndDataExceptionsDataLoadingPayload;
    },
    setIsEmailsDataLoading(
      state,
      action: PayloadAction<IsEmailsDataLoadingPayloadType>,
    ) {
      state.isEmailsDataLoading = action.payload.isEmailsDataLoadingPayload;
    },
    addRowIdsForReloadData(state, action: PayloadAction<string>) {
      if (!state.rowIdsForReloadData.includes(action.payload)) {
        state.rowIdsForReloadData.push(action.payload);
      }
    },
    removeRowIdForReloadData(state, action: PayloadAction<string>) {
      state.rowIdsForReloadData = state.rowIdsForReloadData.filter(
        rowId => rowId !== action.payload,
      );
    },
    UpdateReloadData(
      state,
      action: PayloadAction<{
        rowId: string;
        lastRunStatus: string;
        lastRunStartTime: string;
        lastRunEndTime: string;
        lastRunDuration: string;
      }>,
    ) {
      const {
        rowId,
        lastRunStatus,
        lastRunStartTime,
        lastRunEndTime,
        lastRunDuration,
      } = action.payload;
      state.updatedReloadData[rowId] = {
        lastRunStatus,
        lastRunStartTime,
        lastRunEndTime,
        lastRunDuration,
      };
    },
    clearReloadData(state, action: PayloadAction<{ rowId: string }>) {
      const { rowId } = action.payload;
      delete state.updatedReloadData[rowId];
    },
    updateRowReloadingInProgress(
      state,
      action: PayloadAction<isReloadingInProgressPayloadType>,
    ) {
      const { rowId, isReloadInProgress } = action.payload;
      state.isReLoadingInProgress[rowId] = isReloadInProgress;
    },
    clearAllRowsReloadingInprogress(state) {
      state.isReLoadingInProgress = {};
    },
  },
});

export const {
  changeDbQueryAndDataExceptionsTableName,
  changeEmailMonitoringTableName,
  setIsDbQueryAndDataExceptionsFilterButtonClicked,
  setDBQueryFilterValues,
  setIsEmailsFilterButtonClicked,
  setEmailFilterValues,
  setIsDbQueryAndDataExceptionsDataLoading,
  setIsEmailsDataLoading,
  addRowIdsForReloadData,
  removeRowIdForReloadData,
  UpdateReloadData,
  clearReloadData,
  updateRowReloadingInProgress,
  clearAllRowsReloadingInprogress,
} = DbMonitoringSlice.actions;

export default DbMonitoringSlice.reducer;
