import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

const Monitoring = (): ReactElement => {
  const renderSelectedTabBody = () => <Outlet />;

  return <>{renderSelectedTabBody()}</>;
};

export default Monitoring;
