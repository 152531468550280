export const APP = {
  FONT: {
    FONT_FAMILY: {
      PRIMARY: 'RedHatDisplay-Regular',
      MEDIUM: 'RedHatDisplay-Medium',
      SEMIBOLD: 'RedHatDisplay-SemiBold',
      BOLD: 'RedHatDisplay-Bold',
      EXTRA_BOLD: 'RedHatDisplay-ExtraBold',
      LIGHT: 'RedHatDisplay-Light',
      ITALIC: 'RedHatDisplay-Italic',
      LIGHT_ITALIC: 'RedHatDisplay-LightItalic',
      MEDIUM_ITALIC: 'RedHatDisplay-MediumItalic',
      SEMIBOLD_ITALIC: 'RedHatDisplay-SemiBoldItalic',
      BOLD_ITALIC: 'RedHatDisplay-BoldItalic',
      EXTRA_BOLD_ITALIC: 'RedHatDisplay-ExtraBoldItalic',
    },
    FONT_COLOR: {
      PRIMARY: '#000000',
      SECONDARY: '#4480A6',
      TERTIARY: '#004893',
    },
    FONT_SIZE: {
      H1: 'clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)',
      H2: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
      H3: '2.25rem',
      H4: '1.75rem', // 28px
      H5: '1.5rem', // 24px
      H6: '1.25rem', // 20px
      BUTTON: '0.875rem',
      SUBTITLE1: '1.125rem', // 18px
      BODY1: '1rem',
      BODY2: '0.875rem',
      BODY3: '0.938rem', // 15px
      CAPTION: '0.75rem',
      SUBTITLE2: '0.875rem', // 14px
      OVERLINE: '0.75rem',
      FONT_SIZE_17PX: '1.063rem',
      FONT_SIZE_16PX: '0.937rem',
      FONT_SIZE_13PX: '0.813rem',
      FONT_SIZE_12PX: '0.8rem',
      FONT_SIZE_10PX: '0.625rem',
    },
    FONT_WEIGHT: {
      H1: '800',
      H2: '800',
      H3: '400',
      H4: '400',
      H5: '400',
      H6: '500',
      FW_600: '600',
      BUTTON: '700',
      SUBTITLE1: '500',
      BODY1: '400',
      BODY2: '400',
      CAPTION: '700',
      SUBTITLE2: '500',
      OVERLINE: '400',
    },
  },
  LINE_HEIGHT: {
    H1: 1.1142857142857143,
    H2: 1.2222222222222223,
    H3: 1.2222222222222223,
    H4: 1.5,
    H5: 1.5,
    H6: 1.5,
    BUTTON: 1.75,
    SUBTITLE1: 1.3333333333333333,
    BODY1: 1.5,
    BODY2: 1.5,
    CAPTION: 1.5,
    SUBTITLE2: 1.57,
    OVERLINE: 2.66,
  },
  PALETTE: {
    MODE: 'light' as const,
    PRIMARY: {
      MAIN: '#5ABFB5',
      LIGHT: '#96e5dc',
      DARK: '#2c8f86',
      CONTRAST_TEXT: '#ffffff',
    },
    DIVIDER: '#E7EBF0',

    COMMON: {
      BLACK: '#1D1D1D',
      BLACK_SUB: '#1E1E1E',
      WHITE: '#fff',
      WATER: '#CEFFFC',
    },
    TEXT: {
      PRIMARY: '#000000',
      SECONDARY: '#4480A6',
      DISABLED: 'rgba(0, 0, 0, 0.38)',
    },
    GREY: {
      SHADE_50: '#F3F6F9',
      SHADE_100: '#E7EBF0',
      SHADE_200: '#E0E3E7',
      SHADE_300: '#CDD2D7',
      SHADE_400: '#B2BAC2',
      SHADE_500: '#A0AAB4',
      SHADE_600: '#6F7E8C',
      SHADE_700: '#3E5060',
      SHADE_800: '#2D3843',
      SHADE_900: '#1A2027',
      SHADE_A100: '#f5f5f5',
      SHADE_A200: '#eeeeee',
      SHADE_A400: '#bdbdbd',
      SHADE_A700: '#616161',
      GRAY_COLUMN: '#8F8F8F',
      SEMI_GRAY: '#D8D8D8',
      SEMI_GRAY_1: '#D6D6D6',
      SEMI_GRAY_2: '#dddddd',
      TERTIARY_COLOR: '#777777',
      BOX_SHADOW: {
        GRAY: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      },
      LIGHT_GREY: '#F2F2F2',
      DISABLE_GREY: '#A0AAB4',
    },
    ERROR: {
      MAIN: '#FF0000',
      LIGHT: '#FFCDD2',
      DARK: '#D32F2F',
      CONTRAST_TEXT: '#fff',
    },
    SUCCESS: {
      MAIN: '#1AA251',
      DARK: '#1AA251',
      LIGHT: '#6AE79C',
      CONTRAST_TEXT: '#fff',
    },
    WARNING: {
      MAIN: '#DEA500',
      DARK: '#AB6800',
      LIGHT: '#FFDC48',
      CONTRAST_TEXT: '#fff',
    },
    SECONDARY: {
      MAIN: '#002968',
      DARK: '#001F53',
      LIGHT: '#04588C',
      CONTRAST_TEXT: '#ffffff',
      BLUE_LIGHT: '#004893',
    },
    TERTIARY: {
      MAIN: '#FAA916',
    },
    INFO: {
      MAIN: '#0288d1',
      DARK: '#1E547B',
      LIGHT: '#91B6D1',
      CONTRAST_TEXT: '#fff',
    },
    CONTRAST_THRESHOLD: 3,
    TONAL_OFFSET: 0.2,
    BACKGROUND: {
      PAPER: '#fff',
      DEFAULT: '#fff',
      CHIP_RED: '#EE2A2A',
      CHIP_ORANGE: '#FAA916',
      CHIP_BLUE: '#004893',
      CHIP_GRAY: '#CCCCCC',
      ORANGE: '#F59F22',
      SEMI_RED: '#CF2E2E',
      CHIP_TEAL: '#5ABFB5',
      BASE_BACKGROUND: '#4480A633',
      GRID_PINK_BACKGROUND: '#FFF5F5',
      // eslint-disable-next-line max-len
      PROGRESS_LINEAR_GRADIENT: `linear-gradient(180deg, rgba(255, 255, 255, 0.34) 0%, rgba(255, 255, 255, 0.00) 55.24%, rgba(0, 0, 0, 0.28) 100%), linear-gradient(270deg, #0584FE 0%, #21DACD 100%)`,
      BLUE_WITH_OPACITY: '#4480A612',
      BLACK_WITH_OPACITY: '#0002042b',
      WHITE_WITH_OPACITY: '#FFFFFFB3',
      WHITE_WITH_80_PERCENT_OPACITY: '#FFFFFFCC',
      DARK_BLUE_WITH_OPACITY: '#00489333',
      PINK_BACKGROUND: '#FFCDCD',
      GREY_BACKGROUND: '#F1F1F1',
    },
    BOX_SHADOW: {
      MAIN: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      PRIMARY_WITH_25_PERCENT_OPACITY: '#5ABFB540',
    },
    TABS: {
      MAIN: '#5ABFB5',
      LIGHT: '#96e5dc',
      DARK: '#2c8f86',
      PRIMARY: '#00BCD4',
      SECONDARY: ' #FF9800',
      DEFAULT: '#19D2B3',
      GRAY: '#1976D233',
      DISABLED: '#1976D20F',
      GRAY_2: '#d7d7d7',
    },
    CARD: {
      BACKGROUND_COLOR: {
        PRIMARY: '#f0fffe',
      },
      FONT_COLOR: {
        PRIMARY: '#a5a5a5',
      },
    },
    DASHBOARD: {
      BACKGROUND_COLOR: {
        PRIMARY: '#f6f6f6',
        SECONDARY: '#F7F7F7',
      },
      BORDER_COLOR: {
        PRIMARY: '#D8D8D8',
      },
    },
    SELECT_INPUT: {
      BORDER_COLOR: {
        PRIMARY: '#ced4da',
        SECONDARY: '#80bdff',
      },
      BOX_SHADOW: {
        PRIMARY: '#007bff40',
      },
      BACKGROUND: {
        LINEAR_GRADIENT: 'linear-gradient(180deg, #FFF 0%, #D8D8D8 100%)',
      },
    },
    TAB_FILTER: {
      BACKGROUND_COLOR: {
        PRIMARY: '#7676801f',
      },
      DIVIDER: {
        BACKGROUND_COLOR: {
          PRIMARY: '#8e8e93',
        },
      },
      BOX_SHADOW: {
        PRIMARY: '#0000003d',
      },
    },
  },
};
