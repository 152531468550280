import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import {
  MRT_RowData as MRTRowData,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { Box, Tooltip } from '@mui/material';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import {
  ERROR_CONSTANTS,
  SORT_ORDER,
} from '@constants/global-constants/constants';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { NoDataFoundDivStyled } from 'src/styles/common/CommonStyles';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { DB_QUERY_AND_DATA_EXCEPTIONS_SCREEN_FILTER_OPTIONS_CONFIG } from './DBQueryAndDataExceptionsScreenFilterConfiguration';
import {
  ConnectionNameTextStyle,
  DbQueryReprocessButtonStyled,
  ImgStyledDiv,
  LastRunStatusContainer,
  LastRunStatusStyledDiv,
  LastRunStatusWithImgStyledDiv,
  LastRunTimeContainer,
  LastRunTimeStyledDiv,
  LastSuccessfulTimeContainer,
  LastSuccessfulTimeStyledDiv,
  RowStatusValue,
  RowStyleDiv,
  RowValue,
  TypeCellSubTextStyle,
} from './DBQueryAndDataExceptionsMonitoringTabStyle';
import { RootState } from 'src/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { getDBQueryAndDataExceptionsMonitoring } from 'src/services/service-handlers/private/monitoring-handlers/MonitoringPrivateService';
import { APP } from 'src/styles/variables';
import { formatDateAndTimeWithTimeZone } from '@utils/miscellaneousUtils';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { updateDBQueryLoad } from 'src/services/service-handlers/private/db-query-handlers/DBQueryPrivateService';
import { updateDataExceptionsLoad } from 'src/services/service-handlers/private/data-exceptions-handlers/DataExceptionsPrivateService';
import {
  DBQueryAndDataExceptionsParams,
  GetDBQueryAndDataExceptionsMonitoringDataDto,
} from 'src/services/service-handlers/private/monitoring-handlers/MonitoringServiceMapper';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import {
  addRowIdsForReloadData,
  changeDbQueryAndDataExceptionsTableName,
  clearAllRowsReloadingInprogress,
  clearReloadData,
  removeRowIdForReloadData,
  setDBQueryFilterValues,
  setIsDbQueryAndDataExceptionsDataLoading,
  setIsDbQueryAndDataExceptionsFilterButtonClicked,
  updateRowReloadingInProgress,
  UpdateReloadData,
} from '@modules/home/state/db-monitoring/DbMonitoringSlice';
import {
  IsDbQueryAndDataExceptionsDataLoadingPayloadType,
  isReloadingInProgressPayloadType,
  SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType,
} from '@modules/home/state/db-monitoring/DbMonitoringTypes';
import { executeAfterTimeout, statusFormat } from '@modules/home/utils/Utils';
import axios, { CancelTokenSource } from 'axios';
interface IDBQueryAndDataExceptionsMonitoringTableState {
  isLoading: boolean;
  hoveredRow: string;
  disableHoverEffect: boolean;
  totalRowCount: number;
  networkErrorState: boolean;
  shouldDialogOpen: boolean;
}
const initialDBQueryAndDataExceptionsMonitoringTableState: IDBQueryAndDataExceptionsMonitoringTableState =
  {
    isLoading: false,
    hoveredRow: '',
    disableHoverEffect: false,
    totalRowCount: 0,
    networkErrorState: false,
    shouldDialogOpen: false,
  };
interface IDBQueryAndDataExceptionsMonitoringProps {
  isUserPortal?: boolean;
}
interface DBQueryAndDataExceptionsRowData {
  taskCategory: string;
  taskType: string;
  taskTypeId: string;
  taskTypeNameId: string;
  connectionName: string;
  lastRunStartTime: string;
  lastRunEndTime: string;
  lastRunDuration: string;
  lastRunStatus: string;
  lastRunStatusReason: string;
  lastRunRecordsCount: number;
  lastSuccessfulRunStartTime: string;
  lastSuccessfulRunEndTime: string;
}
const DBQueryAndDataExceptionMonitoring = (
  props: IDBQueryAndDataExceptionsMonitoringProps,
): ReactElement => {
  const { isUserPortal = false } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [
    dbQueryAndDataExceptionsMonitoringTableState,
    setDBQueryAndDataExceptionsMonitoringTableState,
  ] = useState<IDBQueryAndDataExceptionsMonitoringTableState>(
    initialDBQueryAndDataExceptionsMonitoringTableState,
  );
  const {
    isLoading,
    totalRowCount,
    disableHoverEffect,
    networkErrorState,
    shouldDialogOpen,
  } = dbQueryAndDataExceptionsMonitoringTableState;
  const defaultSortOrder: MRTSortingState = [
    {
      id: SORT_ORDER.TASK_TYPE,
      desc: false,
    },
  ];
  const {
    DbMonitoringAndDataExceptionsTableName,
    isDbQueryAndDataExceptionsFilterButtonClicked:
      isDbMonitoringAndDataExceptionsFilterButtonClicked,
    dbQueryFilterOptions,
    rowIdsForReloadData,
    isReLoadingInProgress,
  } = useSelector((state: RootState) => state.dbMonitoring);

  // API region starts
  const cancelTokenSourceForDBQueryAnDataExceptionsData =
    useRef<CancelTokenSource | null>(null);
  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    try {
      cancelTokenSourceForDBQueryAnDataExceptionsData.current =
        axios.CancelToken.source();
      setDBQueryAndDataExceptionsMonitoringTableState(
        produce(draft => {
          draft.isLoading = true;
        }),
      );
      const sortOrder =
        sorting && sorting.length > 0 ? sorting[0] : defaultSortOrder[0];
      const payload: DBQueryAndDataExceptionsParams = {
        orderBy: sortOrder.id,
        orderByKey: sortOrder.desc === true ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      };
      if (dbQueryFilterOptions?.taskTypeId) {
        payload.taskTypeId = dbQueryFilterOptions.taskTypeId as string;
      }
      if (dbQueryFilterOptions?.taskCategoryId) {
        payload.taskCategoryId = dbQueryFilterOptions?.taskCategoryId as string;
      }
      if (dbQueryFilterOptions?.status) {
        payload.status = dbQueryFilterOptions.status as string;
      }
      const dbQueryAndDataExceptionsDataRes =
        await getDBQueryAndDataExceptionsMonitoring(
          payload,
          cancelTokenSourceForDBQueryAnDataExceptionsData.current,
        );
      const dataRows: DBQueryAndDataExceptionsRowData[] = [];
      const dataException: GetDBQueryAndDataExceptionsMonitoringDataDto =
        dbQueryAndDataExceptionsDataRes?.data?.length
          ? dbQueryAndDataExceptionsDataRes.data[0]
          : {
              taskCategoryLabel: '',
              taskTypeId: '',
              taskTypeLabel: '',
              taskTypeNameId: '',
              connectionName: '',
              lastRunStartTime: '',
              lastRunEndTime: '',
              lastRunDuration: '',
              lastRunRecordsCount: 0,
              lastRunStatus: '',
              lastRunStatusReason: '',
              lastSuccessfulRunEndTime: '',
              lastSuccessfulRunStartTime: '',
            };
      dataRows.push({
        taskCategory: t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.DATA_EXCEPTIONS',
        ),
        taskType: '',
        taskTypeId: '',
        taskTypeNameId: '',
        connectionName: dataException.connectionName || '',
        lastRunStartTime: dataException.lastRunStartTime || '',
        lastRunEndTime: dataException.lastRunEndTime || '',
        lastRunDuration: dataException.lastRunDuration || '',
        lastRunStatus: dataException.lastRunStatus || '',
        lastRunStatusReason: dataException.lastRunStatusReason || '',
        lastRunRecordsCount: dataException.lastRunRecordsCount,
        lastSuccessfulRunStartTime:
          dataException.lastSuccessfulRunStartTime || '',
        lastSuccessfulRunEndTime: dataException.lastSuccessfulRunEndTime || '',
      });
      dbQueryAndDataExceptionsDataRes?.data?.length &&
        dbQueryAndDataExceptionsDataRes?.data.slice(1).forEach(dbQueryItem => {
          dataRows.push({
            taskCategory: dbQueryItem.taskCategoryLabel || '',
            taskType: dbQueryItem.taskTypeLabel || '',
            taskTypeId: dbQueryItem.taskTypeId || '',
            connectionName: dbQueryItem.connectionName || '',
            lastRunStartTime: dbQueryItem.lastRunStartTime || '',
            lastRunEndTime: dbQueryItem.lastRunEndTime || '',
            lastRunDuration: dbQueryItem.lastRunDuration || '',
            lastRunStatus: dbQueryItem.lastRunStatus || '',
            lastRunStatusReason: dbQueryItem.lastRunStatusReason || '',
            lastRunRecordsCount: dbQueryItem.lastRunRecordsCount,
            lastSuccessfulRunStartTime:
              dbQueryItem.lastSuccessfulRunStartTime || '',
            lastSuccessfulRunEndTime:
              dbQueryItem.lastSuccessfulRunEndTime || '',
            taskTypeNameId: dbQueryItem.taskTypeNameId || '',
          });
        });
      setDBQueryAndDataExceptionsMonitoringTableState(
        produce(draft => {
          draft.totalRowCount = dataRows.length;
          draft.isLoading = false;
          draft.networkErrorState = false;
        }),
      );
      return dataRows;
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      const isNetworkError =
        e.code === ERROR_CONSTANTS.ERR_NETWORK ||
        e.statusCode === 500 ||
        (e.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setDBQueryAndDataExceptionsMonitoringTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setDBQueryAndDataExceptionsMonitoringTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isLoading = false;
        }),
      );
    } finally {
      cancelTokenSourceForDBQueryAnDataExceptionsData.current = null;
    }
  };
  // API region ends

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForDBQueryAnDataExceptionsData.current) {
        cancelTokenSourceForDBQueryAnDataExceptionsData.current.cancel();
      }
    };
  }, [cancelTokenSourceForDBQueryAnDataExceptionsData]);

  useEffect(() => {
    const payload: IsDbQueryAndDataExceptionsDataLoadingPayloadType = {
      isDbQueryAndDataExceptionsDataLoadingPayload: isLoading,
    };
    dispatch(setIsDbQueryAndDataExceptionsDataLoading(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleReloadButtonClick = async (
    taskTypeId: string,
    taskCategory: string,
    rowId: string,
    taskTypeNameId: string,
  ) => {
    try {
      const payloadForRowReload: isReloadingInProgressPayloadType = {
        rowId: rowId,
        isReloadInProgress: true,
      };
      dispatch(updateRowReloadingInProgress(payloadForRowReload));
      if (
        taskCategory ===
        AdminConstants.MONITORING_TABS
          .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.DATA_EXCEPTIONS
      ) {
        dispatch(addRowIdsForReloadData(taskCategory));
        const payloadForReloadData: any = {
          rowId: taskCategory,
          lastRunStatus: t('ADMIN.SETTINGS_TAB.MONITORING_TAB.IN_PROGRESS'),
          lastRunStartTime: new Date().toISOString(),
          lastRunEndTime: '',
          lastRunDuration: '',
        };
        dispatch(UpdateReloadData(payloadForReloadData));

        const updateDataExceptionsRes = await updateDataExceptionsLoad();
        enqueueSnackbar(
          updateDataExceptionsRes.data.message,
          SnackBarConfig.getSuccess(),
        );
      } else {
        dispatch(addRowIdsForReloadData(rowId));
        const payloadForReloadData: any = {
          rowId: rowId,
          lastRunStatus: t('ADMIN.SETTINGS_TAB.MONITORING_TAB.IN_PROGRESS'),
          lastRunStartTime: new Date().toISOString(),
          lastRunEndTime: '',
          lastRunDuration: '',
        };
        dispatch(UpdateReloadData(payloadForReloadData));

        const payload = { taskTypeNameIds: [taskTypeNameId] };
        const updateDBQueryLoadRes = await updateDBQueryLoad(payload);
        enqueueSnackbar(
          updateDBQueryLoadRes.data.message,
          SnackBarConfig.getSuccess(),
        );
      }
    } catch (errorResponse) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      const rowIdForReloadData =
        taskCategory ===
        AdminConstants.MONITORING_TABS
          .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.DATA_EXCEPTIONS
          ? taskCategory
          : rowId;

      const payloadForReloadData: any = {
        rowId: rowIdForReloadData,
      };
      dispatch(clearReloadData(payloadForReloadData));

      const payloadForRowReload: isReloadingInProgressPayloadType = {
        rowId: rowId,
        isReloadInProgress: false,
      };
      dispatch(updateRowReloadingInProgress(payloadForRowReload));

      dispatch(removeRowIdForReloadData(rowIdForReloadData));
      dispatch(changeDbQueryAndDataExceptionsTableName());
    }
  };

  const handleFilterButtonClick = () => {
    setDBQueryAndDataExceptionsMonitoringTableState(
      produce(draft => {
        draft.shouldDialogOpen = true;
      }),
    );
  };

  const dialogClose = () => {
    setDBQueryAndDataExceptionsMonitoringTableState(
      produce(draft => {
        draft.shouldDialogOpen = false;
      }),
    );
  };

  const handleApplyFilterBtnClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }
    dispatch(setDBQueryFilterValues({ data: data }));

    const values = Object.values(data);
    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        rowIdsForReloadData.forEach(rowId => {
          dispatch(clearReloadData({ rowId }));
        });
        dispatch(clearAllRowsReloadingInprogress());
        dispatch(changeDbQueryAndDataExceptionsTableName());
        break;
      }
    }
    executeAfterTimeout(() => {
      setDBQueryAndDataExceptionsMonitoringTableState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  const handleClearFilterBtnClick = () => {
    if (
      dbQueryFilterOptions?.searchValue ||
      dbQueryFilterOptions?.taskTypeId ||
      dbQueryFilterOptions?.taskCategoryId ||
      dbQueryFilterOptions?.status
    ) {
      rowIdsForReloadData.forEach(rowId => {
        dispatch(clearReloadData({ rowId }));
      });
      dispatch(clearAllRowsReloadingInprogress());
      dispatch(
        setDBQueryFilterValues({
          data: {
            searchKey: '',
            searchValue: '',
            taskTypeId: '',
            taskCategoryId: '',
            status: '',
          },
        }),
      );
      dispatch(changeDbQueryAndDataExceptionsTableName());
    }
    executeAfterTimeout(() => {
      setDBQueryAndDataExceptionsMonitoringTableState(
        produce(draft => {
          draft.shouldDialogOpen = false;
        }),
      );
    }, 100);
  };

  useEffect(() => {
    if (isDbMonitoringAndDataExceptionsFilterButtonClicked) {
      const setIsDbMonitoringAndDataExceptionsFilterButtonClickedPayload: SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType =
        {
          isDbQueryAndDataExceptionsFilterButtonClickedPayload: false,
        };
      dispatch(
        setIsDbQueryAndDataExceptionsFilterButtonClicked(
          setIsDbMonitoringAndDataExceptionsFilterButtonClickedPayload,
        ),
      );
      handleFilterButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDbMonitoringAndDataExceptionsFilterButtonClicked]);

  // render functions starts
  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={`${column.columnDef.header}`}>
        <div>{column.columnDef.header}</div>
      </Tooltip>
    );
  };

  const renderTaskTypeAndTaskCategory = (rowData: {
    taskCategory: string;
    taskType: string;
  }) => {
    const { taskType, taskCategory } = rowData;
    const isDataExceptions =
      taskCategory ===
      AdminConstants.MONITORING_TABS.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB
        .DATA_EXCEPTIONS;
    return (
      <div>
        <RowStyleDiv>{isDataExceptions ? taskCategory : taskType}</RowStyleDiv>
        {!isDataExceptions ? (
          <TypeCellSubTextStyle>
            {taskCategory.length > 0 ? `(${taskCategory})` : ''}
          </TypeCellSubTextStyle>
        ) : null}
      </div>
    );
  };

  const renderConnectionName = (rowData: { connectionName: string }) => {
    const { connectionName } = rowData;
    return (
      <div>
        <ConnectionNameTextStyle>{connectionName}</ConnectionNameTextStyle>
      </div>
    );
  };

  const renderLastRunTime = (rowData: {
    lastRunStartTime: Date;
    lastRunEndTime: Date;
    lastRunDuration: string;
  }) => {
    const { lastRunStartTime, lastRunEndTime, lastRunDuration } = rowData;
    const formattedLastRunStartTime = lastRunStartTime
      ? formatDateAndTimeWithTimeZone(lastRunStartTime)
      : '-';
    const formattedLastRunEndTime = lastRunEndTime
      ? formatDateAndTimeWithTimeZone(lastRunEndTime)
      : '-';

    return (
      <LastRunTimeContainer>
        <LastRunTimeStyledDiv>
          <RowValue>{formattedLastRunStartTime}</RowValue>
          <RowValue>-</RowValue>
          <RowValue>{formattedLastRunEndTime}</RowValue>
        </LastRunTimeStyledDiv>
        {lastRunDuration?.length ? (
          <LastRunTimeStyledDiv>
            <TypeCellSubTextStyle>{`(${lastRunDuration})`}</TypeCellSubTextStyle>
          </LastRunTimeStyledDiv>
        ) : null}
      </LastRunTimeContainer>
    );
  };

  const renderLastRunStatus = (rowData: {
    lastRunStatus: string;
    lastRunStatusReason: string;
    lastRunRecordsCount: number | null;
  }) => {
    const { lastRunStatus, lastRunStatusReason, lastRunRecordsCount } = rowData;

    const formattedStatus = lastRunStatus
      ? lastRunStatus ===
        AdminConstants.MONITORING_TABS
          .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.IN_PROGRESS
        ? statusFormat(lastRunStatus)
        : lastRunStatus
      : '';

    const colorForStatus =
      lastRunStatus.toLowerCase() ===
      AdminConstants.MONITORING_TABS.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB
        .COMPLETED
        ? APP.PALETTE.SUCCESS.MAIN
        : lastRunStatus.toLowerCase() ===
          AdminConstants.MONITORING_TABS
            .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.IN_PROGRESS
        ? APP.PALETTE.TERTIARY.MAIN
        : lastRunStatus.toLowerCase() ===
          AdminConstants.MONITORING_TABS
            .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.FAILED
        ? APP.PALETTE.ERROR.MAIN
        : '';

    return (
      <LastRunStatusContainer>
        <LastRunStatusWithImgStyledDiv>
          {lastRunStatus ? (
            <ImgStyledDiv>
              <CircleRoundedIcon
                sx={{ height: '18px', color: colorForStatus }}
              />
            </ImgStyledDiv>
          ) : null}
          <LastRunStatusStyledDiv>
            <RowStatusValue>{formattedStatus}</RowStatusValue>
          </LastRunStatusStyledDiv>
        </LastRunStatusWithImgStyledDiv>
        {lastRunStatus.toLowerCase() ===
        AdminConstants.MONITORING_TABS
          .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.FAILED ? (
          <LastRunStatusStyledDiv>
            <TypeCellSubTextStyle>{`(${lastRunStatusReason})`}</TypeCellSubTextStyle>
          </LastRunStatusStyledDiv>
        ) : lastRunRecordsCount !== null ? (
          <LastRunStatusStyledDiv>
            <TypeCellSubTextStyle>
              {`(${lastRunRecordsCount} ${t(
                'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.RECORDS_ARE_FETCHED',
              )})`}
            </TypeCellSubTextStyle>
          </LastRunStatusStyledDiv>
        ) : null}
      </LastRunStatusContainer>
    );
  };

  const renderLastSuccessfulTime = (rowData: {
    lastSuccessfulRunStartTime: Date;
    lastSuccessfulRunEndTime: Date;
    lastRunRecordsCount: number | null;
  }) => {
    const {
      lastSuccessfulRunStartTime,
      lastSuccessfulRunEndTime,
      lastRunRecordsCount,
    } = rowData;
    const formattedLastSuccessfulRunStartTime = lastSuccessfulRunStartTime
      ? formatDateAndTimeWithTimeZone(lastSuccessfulRunStartTime)
      : '';
    const formattedLastSuccessfulRunEndTime = lastSuccessfulRunEndTime
      ? formatDateAndTimeWithTimeZone(lastSuccessfulRunEndTime)
      : '';

    return (
      <LastSuccessfulTimeContainer>
        <LastSuccessfulTimeStyledDiv>
          <RowValue>{formattedLastSuccessfulRunStartTime}</RowValue>
          <RowValue>-</RowValue>
          <RowValue>{formattedLastSuccessfulRunEndTime}</RowValue>
        </LastSuccessfulTimeStyledDiv>
        {lastRunRecordsCount !== null ? (
          <LastSuccessfulTimeStyledDiv>
            <TypeCellSubTextStyle>
              {`(${lastRunRecordsCount} ${t(
                'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.RECORDS_ARE_FETCHED',
              )})`}
            </TypeCellSubTextStyle>
          </LastSuccessfulTimeStyledDiv>
        ) : null}
      </LastSuccessfulTimeContainer>
    );
  };

  const renderReLoadButton = (
    rowData: {
      taskTypeId: string;
      taskCategory: string;
      lastRunStatus: string;
      taskTypeNameId: string;
    },
    row: any,
  ) => {
    const { taskTypeId, taskCategory, lastRunStatus, taskTypeNameId } = rowData;
    const rowId = row?.original?.taskTypeId ?? '';

    const isReLoadButtonDisabled =
      lastRunStatus.toLowerCase() ===
        AdminConstants.MONITORING_TABS
          .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.IN_PROGRESS ||
      isReLoadingInProgress[rowId];

    const reloadButtonText = isReLoadingInProgress[rowId]
      ? t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.RELOADING',
        )
      : t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.RELOAD',
        );
    return (
      <DbQueryReprocessButtonStyled
        disabled={isReLoadButtonDisabled}
        variant="outlined"
        onClick={() =>
          handleReloadButtonClick(
            taskTypeId,
            taskCategory,
            rowId,
            taskTypeNameId,
          )
        }
      >
        <span>{reloadButtonText}</span>
      </DbQueryReprocessButtonStyled>
    );
  };

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ): ReactElement => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };
  // render functions ends

  const columnsData: MRTColumnDef<MRTRowData>[] = useMemo(
    () => {
      const baseColumns: MRTColumnDef<MRTRowData>[] = [
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .TASK_TYPE,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.TASK_TYPE',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            taskCategory: row?.taskCategory ?? '',
            taskType: row?.taskType ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderTaskTypeAndTaskCategory(renderedCellValue),
          grow: true,
          size: 100,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .CONNECTION_NAME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.CONNECTION_NAME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            connectionName: row?.connectionName ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderConnectionName(renderedCellValue),
          grow: true,
          size: 110,
          enableSorting: false,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .LAST_RUN_TIME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.LAST_RUN_TIME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            lastRunStartTime: row?.lastRunStartTime ?? '',
            lastRunEndTime: row?.lastRunEndTime ?? '',
            lastRunDuration: row?.lastRunDuration ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderLastRunTime(renderedCellValue),
          grow: true,
          size: 190,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .LAST_RUN_STATUS,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.LAST_RUN_STATUS',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            lastRunStatus: row?.lastRunStatus ?? '',
            lastRunStatusReason: row?.lastRunStatusReason ?? '',
            lastRunRecordsCount: row?.lastRunRecordsCount ?? null,
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderLastRunStatus(renderedCellValue),
          grow: true,
          size: 120,
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .LAST_SUCCESSFUL_TIME,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.LAST_SUCCESSFUL_TIME',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            lastSuccessfulRunStartTime: row?.lastSuccessfulRunStartTime,
            lastSuccessfulRunEndTime: row?.lastSuccessfulRunEndTime,
            lastRunRecordsCount: row?.lastRunRecordsCount ?? null,
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderLastSuccessfulTime(renderedCellValue),
          grow: true,
          size: 190,
          // enableSorting: false
        },
        {
          accessorKey:
            AdminConstants.MONITORING_TABS
              .DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_TAB.COLUMN_ACCESSOR_KEY
              .ACTION,
          header: t(
            'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.ACTION',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            taskTypeId: row?.taskTypeId ?? '',
            taskCategory: row?.taskCategory ?? '',
            lastRunStatus: row?.lastRunStatus ?? '',
            taskTypeNameId: row?.taskTypeNameId ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderReLoadButton(renderedCellValue, row),
          grow: true,
          size: 120,
          enableSorting: false,
        },
      ];
      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReLoadingInProgress],
  );

  return (
    <>
      <Box>
        {networkErrorState ? (
          <NoDataFoundDivStyled>{t('NO_DATA_FOUND')}</NoDataFoundDivStyled>
        ) : (
          <ServerSideDataGrid
            isUserPortal={isUserPortal}
            fetchDataFnQueryKeyName={DbMonitoringAndDataExceptionsTableName}
            fetchDataFn={fetchData}
            dataFetchSize={
              AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
            }
            totalRowsCount={totalRowCount}
            columns={columnsData}
            state={{ isLoading }}
            enableBottomToolbar={false}
            muiTableBodyRowProps={({ row }) => {
              return {
                onMouseEnter: () => {
                  if (disableHoverEffect) return;
                  setDBQueryAndDataExceptionsMonitoringTableState(
                    produce(draft => {
                      draft.hoveredRow = row?.original?.taskTypeId || '';
                    }),
                  );
                },
                onMouseLeave: () => {
                  if (disableHoverEffect) return;
                  setDBQueryAndDataExceptionsMonitoringTableState(
                    produce(draft => {
                      draft.hoveredRow = ' ';
                    }),
                  );
                },
                sx: {
                  borderBottom:
                    row.original?.taskCategory === 'Data Exceptions'
                      ? `5px solid ${APP.PALETTE.BACKGROUND.CHIP_GRAY}`
                      : 'none', // Default color
                },
              };
            }}
            muiCircularProgressProps={{
              color: 'secondary',
              thickness: 5,
              size: 55,
            }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
            enableTableFooter={false}
            getRowId={row => row?.original?.taskTypeNameId ?? ''}
          />
        )}
      </Box>
      {renderSearchAndFilterDropdownDialog({
        open: shouldDialogOpen,
        configuration:
          DB_QUERY_AND_DATA_EXCEPTIONS_SCREEN_FILTER_OPTIONS_CONFIG,
        handleCloseBtnClick: dialogClose,
        handleApplyFilterClick: data => handleApplyFilterBtnClick(data),
        handleClearFilterClick: handleClearFilterBtnClick,
      })}
    </>
  );
};

export default DBQueryAndDataExceptionMonitoring;
