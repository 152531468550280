import React, { ReactElement, ReactNode, ElementType } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Loader from '@components/loaders/Loader';
import { Box, ListItem, ListItemText } from '@mui/material';
import styled from '@emotion/styled';

interface ISelectSearchInputProps<T>
  extends Omit<
    AutocompleteProps<T, boolean, boolean, boolean, ElementType<any>>,
    'renderInput'
  > {
  options: T[]; // Options will be an array of type T
  renderInput: (params: object) => ReactNode;
  loading?: boolean;
  setIsOptionDropdownOpen?: (value: boolean) => void;
  shouldRenderOptionsManually?: boolean;
}

const RenderInputContainer = styled.div<{ loading: boolean }>`
  position: ${({ loading = false }) => (loading ? 'relative' : 'static')};
`;

const LoaderBox = styled(Box)`
  position: absolute;
  top: 17px;
  right: 10px;
`;

const SelectSearchInput = <T,>(
  props: ISelectSearchInputProps<T>,
): ReactElement => {
  const {
    options,
    renderInput,
    loading = false,
    setIsOptionDropdownOpen = undefined,
    shouldRenderOptionsManually = false,
    ...rest
  } = props;

  return shouldRenderOptionsManually ? (
    <Autocomplete
      {...rest}
      options={options}
      onOpen={() => setIsOptionDropdownOpen && setIsOptionDropdownOpen(true)} // Set isOpen to true when options are open
      onClose={() => setIsOptionDropdownOpen && setIsOptionDropdownOpen(false)} // Set isOpen to false when options are closed
      // eslint-disable-next-line @typescript-eslint/no-shadow
      renderOption={(props, option: any) => (
        <ListItem {...props} key={option.id + crypto.randomUUID()}>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
      renderInput={params => (
        <RenderInputContainer loading={loading ? (1 as any) : (0 as any)}>
          {loading && (
            <LoaderBox>
              <Loader size={20} />
            </LoaderBox>
          )}
          {renderInput(params)}
        </RenderInputContainer>
      )}
    />
  ) : (
    <Autocomplete
      {...rest}
      options={options}
      onOpen={() => setIsOptionDropdownOpen && setIsOptionDropdownOpen(true)} // Set isOpen to true when options are open
      onClose={() => setIsOptionDropdownOpen && setIsOptionDropdownOpen(false)} // Set isOpen to false when options are closed
      renderInput={params => (
        <RenderInputContainer loading={loading ? (1 as any) : (0 as any)}>
          {loading && (
            <LoaderBox>
              <Loader size={20} />
            </LoaderBox>
          )}
          {renderInput(params)}
        </RenderInputContainer>
      )}
    />
  );
};

export default SelectSearchInput;
