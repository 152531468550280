import { USER_TYPES } from '@constants/global-constants/constants';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { APP } from 'src/styles/variables';

interface ISideNavBarMenuContainerProps {
  userType?: string;
}

interface IMenuItemContainerProps {
  isActive: boolean;
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

// Right side container for route's body
export const NavMenuBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  width: calc(100vw - 6.3125rem);
  flex: 1;
`;

export const SideNavBarMenuContainer = styled.div<ISideNavBarMenuContainerProps>`
  /* width: 6.25rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #d8d8d8;
  background-color: ${props =>
    props?.userType == USER_TYPES.ADMIN ? APP.PALETTE.SECONDARY.MAIN : 'none'};
`;

export const LogoContainer = styled.div`
  margin-top: 21px;
  cursor: pointer;

  @media (max-height: 440px) {
    margin-top: 0.5rem;
  }
`;

export const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-height: 440px) {
    overflow-y: auto;
    padding-top: 70px;
  }
`;

export const MenuItemContainer = styled.div<IMenuItemContainerProps>`
  background-color: ${props =>
    props.isActive ? APP.PALETTE.TERTIARY.MAIN : 'none'};
  border-radius: 20px;
  margin: 0 0.625rem 0.5rem 0.625rem;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileMenuItemContainer = styled.div`
  margin-bottom: 2.6875rem;
  cursor: pointer;

  @media (max-height: 440px) {
    margin-bottom: 0.5rem;
  }
`;

export const HMDLogoImage = styled.img`
  width: 3.625rem;
  height: 3.625rem;
  cursor: pointer;
`;

export const LoadingGridForProfilePic = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
`;
