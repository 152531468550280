import styled from '@emotion/styled';
import { ReactComponent as MoreIcon } from '@images/More.svg';
import { Box, MenuItem } from '@mui/material';
import { APP } from 'src/styles/variables';

export const UsersTableContainer = styled.div``;

export const DataNotFoundDiv = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${APP.PALETTE.GREY.SEMI_GRAY};
`;

// new styles mine
export const Header = styled.div`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const NameCellContainer = styled.div`
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any content that overflows its container */
  text-overflow: ellipsis;
`;

export const PrimaryCell = styled.div`
  color: ${APP.PALETTE.COMMON.BLACK};
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};
  font-size: ${APP.FONT.FONT_SIZE.BODY1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};

  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any content that overflows its container */
  text-overflow: ellipsis;
`;

export const TaskCategorySubCell = styled(PrimaryCell)`
  color: ${APP.PALETTE.GREY.TERTIARY_COLOR};
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_12PX};
`;

export const EmailCell = styled(PrimaryCell)`
  color: ${APP.FONT.FONT_COLOR.TERTIARY};
`;

export const PhoneCell = styled(PrimaryCell)`
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const TitleCell = styled(PhoneCell)``;

export const RoleCell = styled(PhoneCell)``;

export const KebabMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const CustomKebabMenuIcon = styled(MoreIcon)``;

export const MenuItemStyled = styled(MenuItem)`
  border-bottom: 1px solid ${APP.PALETTE.COMMON.WHITE};
  color: ${APP.PALETTE.COMMON.WHITE};
  font-size: ${APP.FONT.FONT_SIZE.BODY3};
  font-weight: ${APP.FONT.FONT_WEIGHT.FW_600};
`;

export const RowActionsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  gap: 1rem;
  width: 100%;
`;

export const KebabMenuWrapper = styled('div')`
  display: inline-flex;
  padding: 0.625rem 1.25rem;
  align-items: flex-start;
  gap: 0.625rem;
`;
