import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TableCellProps } from '@mui/material';
import { t } from 'i18next';
import {
  MRT_Row as MRTRow,
  MRT_RowData as MRTRowData,
  MRT_Column as MRTColumn,
  MRT_RowVirtualizer as MRTRowVirtualizer,
  MRT_SortingState as MRTSortingState,
  MRT_RowSelectionState as MRTRowSelectionState,
  MRT_TableInstance as MRTTableInstance,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { useInfiniteQuery } from '@tanstack/react-query';

import { DashboardDataGridWrapper } from './DashboardDataGridStyle';

import CustomDataGrid from '../CustomDataGrid';
import { APP } from 'src/styles/variables';

interface IDashboardDataGridProps<TData extends MRTRowData> {
  columns: MRTColumnDef<TData>[];
  fetchDataFnQueryKeyName?: string;
  isRowActionsEnabled?: boolean;
  isRowSelectionEnabled?: boolean;
  totalRowsCount?: number;
  fetchInfiniteQueryDataFn: (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => Promise<TData[] | any>;
  dataFetchSize?: number;
  showBottomProgressBar?: boolean;
  rowSelection?: MRTRowSelectionState;
  columnFilters?: Array<any> | null;
  onSortChange?: (data: any) => void;
  globalFilter?: string;
  isDataLoading?: boolean;
  enableSorting?: boolean;
  handleFetchData?: () => void;
  muiTableHeadCellProps?:
    | TableCellProps
    | ((props: {
        column: MRTColumn<TData>;
        table: MRTTableInstance<TData>;
      }) => TableCellProps)
    | undefined;
  state?: any;
}

const DashboardServerSideDataGrid = <TData extends MRTRowData>(
  props: IDashboardDataGridProps<TData>,
): ReactElement => {
  const {
    columns,
    isRowActionsEnabled: enableRowActions = false,
    isRowSelectionEnabled: enableRowSelection = false,
    fetchDataFnQueryKeyName = t('PRIVATE.DATA_TABLE.QUERY_KEY_NAME'),
    fetchInfiniteQueryDataFn,
    muiTableHeadCellProps = undefined,
    rowSelection,
    columnFilters = [],
    onSortChange,
    globalFilter = '',
    // isDataLoading = false,
    showBottomProgressBar = false,
    totalRowsCount = 0,
    dataFetchSize = 50,
    enableSorting = false,
    state,
    ...rest
  } = props;
  const fetchSize = dataFetchSize;
  const rowVirtualizerInstanceRef = useRef<MRTRowVirtualizer>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [sorting, setSorting] = useState<MRTSortingState>([]);

  const OverrideTablePaperProps = () => {
    return {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: 'none !important',
      },
    };
  };

  const OverrideTableBodyCellProps = () => {
    return {
      sx: {
        padding: '0.5rem 0.2rem',
        display: 'flex',
        justifyContent: 'center',
      },
    };
  };

  const OverrideTableBodyRowProps = (row: MRTRow<TData>) => {
    return {
      sx: {
        backgroundColor:
          row.depth == 1
            ? APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY
            : 'transparent',
        height: '3.875rem',
      },
    };
  };

  const styleOverrides = {
    // this is the wrapper box covering mui table
    muiTablePaperProps: OverrideTablePaperProps(),
    // override table body cells
    muiTableBodyCellProps: OverrideTableBodyCellProps(),
    // conditionally rendering 1st level nested rows
    muiTableBodyRowProps: ({ row }: { row: MRTRow<TData> }) =>
      OverrideTableBodyRowProps(row),
  };

  const { data, fetchNextPage, isError, isFetching } = useInfiniteQuery({
    queryKey: [fetchDataFnQueryKeyName, columnFilters, globalFilter, sorting],
    queryFn: ({ pageParam }) =>
      fetchInfiniteQueryDataFn(pageParam, fetchSize, sorting),
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    onSortChange?.(sorting);
  }, [onSortChange, sorting]);

  const flatData = useMemo(() => {
    if (data && 'pages' in data) return data?.pages.flat();
    return [];
  }, [data]);

  const totalDBRowCount = totalRowsCount;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  useEffect(() => {
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const defaultConfig = {
    muiTableHeadCellProps: muiTableHeadCellProps ?? {
      sx: {
        color: APP.PALETTE.GREY.GRAY_COLUMN,
        fontSize: APP.FONT.FONT_SIZE.BODY2,
        fontWeight: APP.FONT.FONT_WEIGHT.BODY1,
        backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY,
      },
    },
  };

  const mergedColumnsData = columns.map(column => ({
    ...defaultConfig,
    ...column,
  }));

  return (
    <DashboardDataGridWrapper>
      <CustomDataGrid
        columns={mergedColumnsData}
        enableRowActions={enableRowActions}
        enableRowVirtualization={true}
        enableBottomToolbar={false}
        muiTableContainerProps={{
          sx: { maxHeight: '800px', minHeight: '400px' },
          ref: tableContainerRef,
          onScroll: (event: any) =>
            fetchMoreOnBottomReached(event.target as HTMLDivElement),
        }}
        manualSorting={false}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: t('PRIVATE.EMAILS.QUEUE_TABLE.ERROR_LOADING_DATA'),
              }
            : undefined
        }
        state={{
          rowSelection: rowSelection || {},
          columnFilters: columnFilters || [],
          globalFilter: globalFilter || '',
          isLoading: isFetching,
          showAlertBanner: isError,
          showProgressBars: showBottomProgressBar ?? isFetching,
          sorting: sorting || [],
          ...state,
        }}
        enableSorting={enableSorting}
        onSortingChange={value => setSorting(value)}
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
        rowVirtualizerOptions={{ overscan: 4 }}
        data={flatData}
        enableRowSelection={enableRowSelection}
        muiCircularProgressProps={{
          sx: {
            color: APP.FONT.FONT_COLOR.TERTIARY,
          },
        }}
        {...styleOverrides}
        {...rest}
      />
    </DashboardDataGridWrapper>
  );
};

export default DashboardServerSideDataGrid;
