import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { produce } from 'immer';
import {
  MRT_RowData as MRTRowData,
  MRT_Row as MRTRow,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';

import {
  AssignedUserDivStyled,
  RowActionsWrapper,
  RowStyleDiv,
  RowStyledDiv,
  TypeCellSubTextStyle,
  ViewButtonStyled,
} from '../queue-table/QueueTableStyle';
import { NoDataFoundDivStyled } from 'src/styles/common/CommonStyles';

import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { formatDate } from '@modules/home/utils/Utils';
import {
  changeTableName,
  reloadQueueTable,
  setIsRefetchingNeeded,
  setParentQueueTabPathName,
  setProfilePicsUrlsWithLoaderFlagById,
} from '@modules/home/state/queue/QueueSlice';
import {
  ERROR_CONSTANTS,
  SORT_ORDER,
  USER_TYPES,
} from '@constants/global-constants/constants';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import AuthUtils from '@utils/AuthUtils';
import { getErrorMessage } from '@utils/ErrorUtils';
import { QueueTableColumns } from '../queue-table/QueueTableConstants';
import {
  AssignedUser,
  QueueParams,
  RowDataDto,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { getAllQueueHistoryList } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { RootState } from 'src/store/Store';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import Loader from '@components/loaders/Loader';
import { LoadingGridForHistoryTabProfilePic } from './QueueHistoryStyle';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import { ProfilePicRefType } from 'src/types/Types';
import { StateNsaAllStateEnum } from 'src/enums/admin-enums/AdminEnums';
import axios, { CancelTokenSource } from 'axios';

interface IQueueHistory {
  isUserPortal?: boolean;
}

interface ITaskListItem extends RowDataDto {
  profilePicUrl?: string;
}

interface IQueueHistoryTableState {
  isLoading: boolean;
  hoveredRow: string;
  isMenuOpen: boolean;
  selectedRow: string | null;
  totalRowCount: number;
  networkErrorState: boolean;
  taskDataRows: ITaskListItem[];
}

const defaultSortOrder: MRTSortingState = [
  {
    id: SORT_ORDER.COMPLETED_DATE,
    desc: true,
  },
];

const QueueHistory = (props: IQueueHistory): ReactElement => {
  const { isUserPortal = false } = props;
  const navigate = useNavigate();
  const userType = AuthUtils.getUserType();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});
  const {
    queueSearchAndFilterOptions,
    isRefetchingNeeded,
    fetchDataFnQueryKeyName,
    profilePicsUrlsWithLoaderFlagById,
  } = useSelector((state: RootState) => state.queue);

  const [queueTableState, setQueueTableState] =
    useState<IQueueHistoryTableState>({
      isLoading: true,
      hoveredRow: '',
      isMenuOpen: false,
      selectedRow: null,
      totalRowCount: 0,
      networkErrorState: false,
      taskDataRows: [],
    });

  const {
    hoveredRow,
    isLoading,
    totalRowCount,
    networkErrorState,
    taskDataRows,
  } = queueTableState;

  // API calls region starts
  const fetchProfilePicById = async (
    userId: string,
    profilePictureId: string,
  ) => {
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  // get Queue All list
  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    cancelTokenSource.current = axios.CancelToken.source();

    try {
      setQueueTableState(
        produce(draft => {
          draft.isLoading = true;
        }),
      );

      let searchAndFilterQueries = {};

      const searchAndFilterStateOptions = { ...queueSearchAndFilterOptions };
      if (searchAndFilterStateOptions?.nsaState) {
        if (
          searchAndFilterStateOptions.nsaState ===
          AdminConstants.USERS.NSA.toLowerCase()
        ) {
          searchAndFilterStateOptions.stateNsa =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        } else if (
          searchAndFilterStateOptions.nsaState ===
          AdminConstants.USERS.STATE.toLowerCase()
        ) {
          searchAndFilterStateOptions.stateNsa =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        } else if (searchAndFilterStateOptions.nsaState !== '') {
          searchAndFilterStateOptions.stateNsa =
            AdminConstants.USERS.STATE.toLowerCase();
          searchAndFilterStateOptions.stateId =
            searchAndFilterStateOptions.nsaState;
          delete searchAndFilterStateOptions.nsaState;
        }
      }

      searchAndFilterStateOptions.status =
        searchAndFilterStateOptions.taskStatus;
      delete searchAndFilterStateOptions.taskStatus;

      if (!searchAndFilterStateOptions.searchValue) {
        delete searchAndFilterStateOptions.searchKey;
      }

      (
        Object.entries(searchAndFilterStateOptions) as [string, string][]
      ).forEach(([key, value]: [string, string]) => {
        if (value) {
          searchAndFilterQueries = { ...searchAndFilterQueries, [key]: value };
        }
      });

      const sortOrder =
        sorting && sorting.length > 0 ? sorting[0] : defaultSortOrder[0];

      const payload: QueueParams = {
        offset: pageParam * fetchSize,
        limit: fetchSize,
        orderBy: sortOrder?.id,
        orderByKey: sortOrder?.desc === true ? SORT_ORDER.DESC : SORT_ORDER.ASC,
        ...searchAndFilterQueries,
      };

      const res = await getAllQueueHistoryList(
        payload,
        cancelTokenSource.current,
      );
      const { data } = res;

      setQueueTableState(
        produce(draft => {
          draft.totalRowCount = data?.count ?? 0;
          draft.isLoading = false;
          draft.networkErrorState = false;
          draft.taskDataRows = data?.rows ?? [];
        }),
      );
      return data?.rows ?? [];
    } catch (e: any) {
      const isNetworkError =
        e.code === ERROR_CONSTANTS.ERR_NETWORK ||
        e.statusCode === 500 ||
        (e.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setQueueTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setQueueTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isLoading = false;
        }),
      );
    } finally {
      cancelTokenSource.current = null;
    }
  };

  // API calls region ends

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    };
  }, []);

  const fetchProfilePictures = async () => {
    for (const taskData of queueTableState.taskDataRows) {
      const assignedUser = taskData?.assignedUser;
      if (assignedUser && assignedUser?.id && assignedUser?.profilePicId) {
        const { id, profilePicId } = assignedUser;
        if (!fetchedProfilePicRef.current[id]) {
          fetchedProfilePicRef.current[id] = true;
          await fetchProfilePicById(id, profilePicId);
        }
      }
    }
  };

  useEffect(() => {
    if (taskDataRows.length > 0) {
      fetchProfilePictures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDataRows]);

  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={column.columnDef.header}>
        <span>{column.columnDef.header}</span>
      </Tooltip>
    );
  };

  const renderCellValue = (value: string) => {
    return (
      <div>
        <RowStyleDiv>{value}</RowStyleDiv>
      </div>
    );
  };

  const renderNSAStateCellValue = (nsaState: string, stateCode: string) => {
    if (nsaState.toLowerCase() === StateNsaAllStateEnum.NSA) {
      return (
        <div>
          <RowStyleDiv>{nsaState.toUpperCase()}</RowStyleDiv>
        </div>
      );
    } else if (nsaState.toLowerCase() === StateNsaAllStateEnum.STATE) {
      return (
        <div>
          <RowStyleDiv>{stateCode.toUpperCase()}</RowStyleDiv>
        </div>
      );
    } else {
      return (
        <div>
          <RowStyleDiv>{''}</RowStyleDiv>
        </div>
      );
    }
  };

  const renderTypeValue = (rowData: {
    taskCategory: string;
    taskType: string;
  }) => {
    const { taskCategory, taskType } = rowData;
    return (
      <div>
        <RowStyleDiv>{taskType}</RowStyleDiv>
        <TypeCellSubTextStyle>{`(${taskCategory})`}</TypeCellSubTextStyle>
      </div>
    );
  };

  const renderAssignedUser = (value: AssignedUser) => {
    const isProfilePictureIdAvailable =
      value?.profilePicId && value?.profilePicId?.length > 0;
    const isProfilePicForUserIdLoading = value?.id
      ? profilePicsUrlsWithLoaderFlagById[value.id]?.isProfilePicLoading
      : false;
    const profilePicUrl = value?.id
      ? profilePicsUrlsWithLoaderFlagById[value.id]?.profilePicUrl
      : '';

    return (
      <Box component="li" sx={{ display: 'flex', alignItems: 'center' }}>
        {isProfilePictureIdAvailable && isProfilePicForUserIdLoading ? (
          <LoadingGridForHistoryTabProfilePic>
            <Loader size={13} />
          </LoadingGridForHistoryTabProfilePic>
        ) : (
          <Avatar
            src={profilePicUrl}
            alt={value?.name ?? ''}
            sx={{ marginRight: 1, width: 20, height: 20 }}
          />
        )}
        <AssignedUserDivStyled>
          {value?.name ? `${value?.name}` : '-'}
        </AssignedUserDivStyled>
      </Box>
    );
  };

  const columnsData: MRTColumnDef<MRTRowData>[] = useMemo(
    () => {
      const baseColumns: MRTColumnDef<MRTRowData>[] = [
        {
          accessorKey: QueueTableColumns.caseId,
          header: t('QUEUE_TABLE.CASE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => renderCellValue(row?.caseId ?? ''),
          grow: true,
          size: 120,
        },
        {
          accessorKey: QueueTableColumns.taskType,
          header: t('QUEUE_TABLE.TYPE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            taskCategory: row?.taskCategory ?? '',
            taskType: row?.taskType ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderTypeValue(renderedCellValue),
          grow: true,
          size: 220,
        },
        {
          accessorKey: QueueTableColumns.customerName,
          header: t('QUEUE_TABLE.CUSTOMER'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>{row?.customerName ?? ''}</RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 180,
        },
        {
          accessorKey: QueueTableColumns.nsaState,
          header: t('QUEUE_TABLE.NSA_STATE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row =>
            renderNSAStateCellValue(row?.nsaState ?? '', row?.stateCode ?? ''),
          grow: true,
          size: 120,
        },
        {
          accessorKey: QueueTableColumns.payorName,
          header: t('QUEUE_TABLE.PAYOR'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>{row?.payorName ?? ''}</RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 200,
        },
        {
          accessorKey: QueueTableColumns.completedOn,
          header: t('QUEUE_TABLE.COMPLETED_DATE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>
                {row?.completedOn ? formatDate(row.completedOn) : ''}
              </RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 120,
        },
        {
          accessorKey: QueueTableColumns.emailDate,
          header: t('QUEUE_TABLE.EMAIL_DATE'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => (
            <div>
              <RowStyledDiv>
                {row?.emailDate ? formatDate(row.emailDate) : ''}
              </RowStyledDiv>
            </div>
          ),
          grow: true,
          size: 120,
        },
      ];

      // Conditionally add the "Assigned" column based on userType
      if (userType !== USER_TYPES.USER) {
        baseColumns.push({
          accessorKey: QueueTableColumns.assignedUser,
          header: t('QUEUE_TABLE.ASSIGNED'),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          Cell: ({ renderedCellValue }: any) =>
            renderAssignedUser(renderedCellValue),
          accessorFn: row => row?.assignedUser,
          grow: true,
        });
      }

      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userType, profilePicsUrlsWithLoaderFlagById],
  );

  const init = () => {
    dispatch(reloadQueueTable({ reloadQueueTableGrid: true }));
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueSearchAndFilterOptions]);

  useEffect(() => {
    if (isRefetchingNeeded) {
      dispatch(setIsRefetchingNeeded({ data: false }));
      dispatch(changeTableName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchingNeeded]);

  const queueDetailsNavigationURL = (row: MRTRow<MRTRowData>) => {
    dispatch(
      setParentQueueTabPathName({
        parentQueueTabPathNameUrl: location.pathname,
      }),
    );
    const navigationUrl =
      userType === USER_TYPES.USER
        ? PrivateNavigationRoutes.User.ChildRoutes.Queue.ChildRoutes.QueueDetails.FullPath.replace(
            ':taskId',
            row.original.id,
          )
        : PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueDetails.FullPath.replace(
            ':taskId',
            row.original.id,
          );

    return navigate(navigationUrl);
  };

  const renderRowActionsSection = (row: MRTRow<MRTRowData>) => {
    return (
      <RowActionsWrapper>
        {hoveredRow ===
          ((row?.original?.caseId || '') + (row?.original?.id || '') ||
            row.id) && (
          <ViewButtonStyled
            variant="outlined"
            onClick={() => queueDetailsNavigationURL(row)}
          >
            {t('ADMIN.QUEUE_TABS.VIEW')}
          </ViewButtonStyled>
        )}
      </RowActionsWrapper>
    );
  };
  return (
    <Box>
      {networkErrorState ? (
        <NoDataFoundDivStyled>{t('NO_DATA_FOUND')}</NoDataFoundDivStyled>
      ) : (
        <ServerSideDataGrid
          fetchDataFnQueryKeyName={fetchDataFnQueryKeyName}
          fetchDataFn={fetchData}
          dataFetchSize={
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
          }
          totalRowsCount={totalRowCount}
          columns={columnsData}
          state={{ isLoading }}
          enableBottomToolbar={false}
          enableSorting={true}
          isUserPortal={isUserPortal}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              height: '6rem',
            },
            onMouseEnter: () => {
              setQueueTableState(
                produce(draft => {
                  draft.hoveredRow =
                    (row?.original?.caseId || '') + (row?.original?.id || '');
                }),
              );
            },
            onMouseLeave: () => {
              setQueueTableState(
                produce(draft => {
                  draft.hoveredRow = ' ';
                }),
              );
            },
          })}
          muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
          }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              size: 200,
              grow: false,
              header: '',
            },
          }}
          enableRowActions={true}
          renderRowActions={({ row, table }) => renderRowActionsSection(row)}
          sortDescFirst={true}
          enableRowSelection={false}
          getRowId={row =>
            `${(row?.original?.caseId || '') + (row?.original?.id || '')}`
          }
        />
      )}
    </Box>
  );
};

export default QueueHistory;
