import {
  useMemo,
  ReactElement,
  useState,
  useEffect,
  MutableRefObject,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Menu, Tooltip } from '@mui/material';
import {
  type MRT_RowSelectionState as MRTRowSelectionState,
  type MRT_ColumnDef as MRTColumnDef,
  MRT_RowData as MRTRowData,
  MRT_Row as MRTRow,
} from 'material-react-table';
import { t } from 'i18next';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';

import {
  CustomKebabMenuIcon,
  DataNotFoundDiv,
  EmailCell,
  Header,
  KebabMenuWrapper,
  MenuItemStyled,
  NameCellContainer,
  PhoneCell,
  PrimaryCell,
  RoleCell,
  RowActionsWrapper,
  TaskCategorySubCell,
  TitleCell,
} from './UsersTableStyle';

import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { APP } from 'src/styles/variables';
import { GetAllUsersFromOurSystemQueryParamFormatEnum } from 'src/enums/admin-enums/AdminEnums';
import { KebabMenuItemType } from 'src/types/adminTypes';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { ERROR_CONSTANTS } from '@constants/global-constants/constants';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { getErrorMessage } from '@utils/ErrorUtils';
import { joinArrayElementsWithComma } from '@utils/miscellaneousUtils';

import {
  GetAllUsersFromOurSystemDataRow,
  GetAllUsersFromOurSystemQueryParam,
  GetAllUsersFromOurSystemResDto,
} from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import MarkAsActiveOrInactiveUserConfirmationDialog from '../mark-as-active-or-inactive-user-confirmation-dialog/MarkAsActiveOrInactiveUserConfirmationDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import {
  changeUsersTableName,
  setIsSearchAndFilterApplied,
  setShouldReloadUsersTableGrid,
} from '@modules/home/state/users/UsersSlice';
import {
  IsSearchAndFilterAppliedPayloadType,
  ShouldReloadUsersTableGridPayloadType,
  UsersTableNamePrefixPayload,
} from '@modules/home/state/users/UsersTypes';
import { CancelTokenSource } from 'axios';

// example data type
export type UsersColumnTypes = {
  userId: string;
  name: string;
  email: string;
  phone: string;
  title: string;
  role: string;
  taskCategories?: string;
};

type ActiveUsersTableStateType = {
  rowSelection: MRTRowSelectionState;
  isLoading: boolean;
  currentClickedUserId: string;
  showKebabDropdownMenu: boolean;
  disableHoverEffect: boolean;
  selectedRow: string | null;
  selectedRowId: string;
  hoveredRow: string;
  isKebabMenuOpen: boolean;
  totalRowCount: number;
  showMarkAsActiveOrInactiveConfirmationDialog: boolean;
  networkErrorState: boolean;
};

interface IUsersTableProps {
  fetchUsers: (
    data?: GetAllUsersFromOurSystemQueryParam,
    cancelTokenSource?: CancelTokenSource | null,
  ) => Promise<GetAllUsersFromOurSystemResDto>;
  cancelTokenSource: MutableRefObject<CancelTokenSource | null>;
  resetCancelTokenSource: () => void;
  kebabMenuItemsList: KebabMenuItemType[];
  fetchDataFnQueryKeyName: string;
  setLimitOffsetAndTotal: (value: number) => void;
}

const UsersTable = (props: IUsersTableProps): ReactElement => {
  const {
    fetchUsers,
    cancelTokenSource,
    resetCancelTokenSource,
    kebabMenuItemsList,
    fetchDataFnQueryKeyName,
  } = props;
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  // const selectedRowIdRef = useRef('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const location = useLocation();

  const isActiveUsersTabSelected = location.pathname.includes('/active');

  const [usersTableState, setUsersTableState] =
    useState<ActiveUsersTableStateType>({
      rowSelection: {},
      isLoading: false,
      currentClickedUserId: '-1',
      showKebabDropdownMenu: false,
      disableHoverEffect: false,
      selectedRow: null,
      selectedRowId: '',
      hoveredRow: '',
      isKebabMenuOpen: false,
      totalRowCount: 0,
      showMarkAsActiveOrInactiveConfirmationDialog: false,
      networkErrorState: false,
    });

  const {
    isLoading,
    currentClickedUserId,
    disableHoverEffect,
    selectedRow,
    hoveredRow,
    totalRowCount,
    isKebabMenuOpen,
    showMarkAsActiveOrInactiveConfirmationDialog,
    networkErrorState,
  } = usersTableState;

  const {
    shouldReloadUsersTableGrid,
    isSearchAndFilterOptionsApplied,
    userSearchAndFilterOptions,
    fetchUsersDataFnQueryKeyName,
  } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (isSearchAndFilterOptionsApplied) {
      const shouldReloadUsersTableGridPayload: ShouldReloadUsersTableGridPayloadType =
        { shouldReloadUsersTableGridPayloadValue: true };
      dispatch(
        setShouldReloadUsersTableGrid(shouldReloadUsersTableGridPayload),
      );

      const changeUserTableNamePayload: UsersTableNamePrefixPayload = {
        usersTableNamePrefix: fetchDataFnQueryKeyName,
      };
      dispatch(changeUsersTableName(changeUserTableNamePayload));

      const isSearchAndFilterAppliedPayload: IsSearchAndFilterAppliedPayloadType =
        { isSearchAndFilterApplied: false };
      dispatch(setIsSearchAndFilterApplied(isSearchAndFilterAppliedPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchAndFilterOptionsApplied]);

  const viewUserProfileNavigationURL = (userId: string) =>
    PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes.UsersSettings.FullPath.replace(
      ':userId',
      userId,
    );

  const formatDataRowsForTable = (data: GetAllUsersFromOurSystemDataRow[]) => {
    if (!data.length) {
      return [];
    }

    const formattedData = data.map(userData => {
      const roleLabels = joinArrayElementsWithComma(
        userData.roles.map(userRoleData => userRoleData.label),
      );

      const taskCategoriesLabels = userData?.taskCategories
        ? joinArrayElementsWithComma(
            userData.taskCategories.map(
              userTaskCategories => userTaskCategories.label,
            ),
          )
        : null;

      const formattedUserData = {
        userId: userData.id,
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        title: userData.title.label,
        role: roleLabels,
        taskCategories: taskCategoriesLabels,
      };
      return formattedUserData;
    });
    return formattedData;
  };

  const fetchData = async (pageParam: number, fetchSize: number) => {
    setUsersTableState(
      produce(draft => {
        draft.isLoading = true;
      }),
    );

    try {
      const currentOffset = pageParam * fetchSize;
      const payloadData: GetAllUsersFromOurSystemQueryParam = {
        isActive: isActiveUsersTabSelected,
        limit: fetchSize,
        offset: currentOffset,
        format: GetAllUsersFromOurSystemQueryParamFormatEnum.DETAILED,
      };

      if (userSearchAndFilterOptions?.searchValue) {
        payloadData.searchKey = userSearchAndFilterOptions?.searchKey as string;
        payloadData.searchValue =
          userSearchAndFilterOptions?.searchValue as string;
      }

      if (userSearchAndFilterOptions?.title) {
        payloadData.title = userSearchAndFilterOptions?.title as string;
      }

      if (userSearchAndFilterOptions?.role) {
        payloadData.role = userSearchAndFilterOptions?.role as string;
      }

      const fetchUsersApiResponse = await fetchUsers(
        payloadData,
        cancelTokenSource.current ?? undefined,
      );

      setUsersTableState(
        produce(draft => {
          draft.totalRowCount = fetchUsersApiResponse.data.count;
          draft.isLoading = false;
        }),
      );

      const formattedDataRows = formatDataRowsForTable(
        fetchUsersApiResponse?.data?.rows ?? [],
      );

      setUsersTableState(
        produce(draft => {
          draft.networkErrorState = false;
        }),
      );
      const shouldReloadUsersTableGridPayload: ShouldReloadUsersTableGridPayloadType =
        {
          shouldReloadUsersTableGridPayloadValue: false,
        };
      dispatch(
        setShouldReloadUsersTableGrid(shouldReloadUsersTableGridPayload),
      );
      return formattedDataRows;
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      const isNetworkError =
        e.code === ERROR_CONSTANTS.ERR_NETWORK ||
        e.statusCode === 500 ||
        (e.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setUsersTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      setUsersTableState(
        produce(draft => {
          draft.isLoading = false;
        }),
      );
      const shouldReloadUsersTableGridPayload: ShouldReloadUsersTableGridPayloadType =
        {
          shouldReloadUsersTableGridPayloadValue: false,
        };
      dispatch(
        setShouldReloadUsersTableGrid(shouldReloadUsersTableGridPayload),
      );

      return false;
    } finally {
      resetCancelTokenSource();
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setUsersTableState(
      produce(draft => {
        draft.showMarkAsActiveOrInactiveConfirmationDialog = false;
        draft.hoveredRow = '-1';
        draft.selectedRow = null;
        draft.isKebabMenuOpen = false;
      }),
    );
  };

  const handleKebabMenuClick = (
    event: React.MouseEvent<HTMLDivElement>,
    rowId: string,
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setUsersTableState(
      produce(draft => {
        draft.selectedRow = rowId;
      }),
    );
  };

  const handleViewProfile = (userId: string) => {
    navigate(viewUserProfileNavigationURL(userId), {
      state: { isUserActive: isActiveUsersTabSelected },
    });
  };

  const handleMarkAsActiveOrInactiveConfirmationOptionClick = () => {
    setUsersTableState(
      produce(draft => {
        draft.showMarkAsActiveOrInactiveConfirmationDialog = true;
      }),
    );
  };

  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={`${column.columnDef.header}`}>
        <Header>{column.columnDef.header}</Header>
      </Tooltip>
    );
  };

  const renderMarkAsActiveOrInactiveConfirmationDialog = () => {
    return (
      <MarkAsActiveOrInactiveUserConfirmationDialog
        userId={currentClickedUserId}
        isUserActive={isActiveUsersTabSelected}
        isDialogOpen={showMarkAsActiveOrInactiveConfirmationDialog}
        onClose={() => {
          setUsersTableState(
            produce(draft => {
              draft.showMarkAsActiveOrInactiveConfirmationDialog = false;
            }),
          );
        }}
        onSubmitCloseDialog={() => {
          setUsersTableState(
            produce(draft => {
              draft.showMarkAsActiveOrInactiveConfirmationDialog = false;
            }),
          );
        }}
      />
    );
  };

  const renderKebabMenu = () => {
    return (
      <Menu
        slotProps={{
          paper: {
            style: {
              backgroundColor: APP.PALETTE.COMMON.BLACK,
              width: '12.5rem',
              flexShrink: 0,
            },
          },
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onMouseLeave={() => {
          setMenuAnchorEl(null);
        }}
        onClose={handleCloseMenu}
      >
        {kebabMenuItemsList.map((item, index) => {
          return (
            <MenuItemStyled
              key={item.id}
              sx={
                index === kebabMenuItemsList.length - 1
                  ? { borderBottom: 'none !important' }
                  : {}
              }
              onClick={() =>
                item.value ===
                AdminConstants.USERS.USERS_KEBAB_MENU_OPTIONS.VIEW_PROFILE
                  ? handleViewProfile(hoveredRow)
                  : handleMarkAsActiveOrInactiveConfirmationOptionClick()
              }
            >
              {item.label}
            </MenuItemStyled>
          );
        })}
      </Menu>
    );
  };

  const renderRowActionsSection = (
    row: MRTRow<MRTRowData> | MRTRow<UsersColumnTypes>,
  ) => {
    return (
      <RowActionsWrapper>
        {hoveredRow === row.id && (
          <>
            <KebabMenuWrapper
              data-row-id={row.id}
              onClick={e => {
                e.stopPropagation();
                handleKebabMenuClick(e, row.id);
                setUsersTableState(
                  produce(draft => {
                    draft.isKebabMenuOpen = true;
                  }),
                );
              }}
            >
              <CustomKebabMenuIcon />
            </KebabMenuWrapper>
            {selectedRow === row.id && renderKebabMenu()}
          </>
        )}
      </RowActionsWrapper>
    );
  };

  const columns = useMemo<MRTColumnDef<UsersColumnTypes>[]>(
    () => [
      {
        id: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.NAME,
        accessorKey: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.NAME,
        header: t('ADMIN.USERS_TAB.NAME'),
        Header: ({ column }: any) => renderHeaderWithTooltip(column),
        accessorFn: row => (
          <NameCellContainer>
            <PrimaryCell>{row?.name ?? ''}</PrimaryCell>
            <TaskCategorySubCell>
              {row.taskCategories ?? ''}
            </TaskCategorySubCell>
          </NameCellContainer>
        ),
        grow: true,
      },
      {
        accessorKey: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.EMAIL,
        Header: ({ column }: any) => renderHeaderWithTooltip(column),
        header: t('ADMIN.USERS_TAB.EMAIL'),
        Cell: ({ renderedCellValue }: any) => (
          <EmailCell>{renderedCellValue}</EmailCell>
        ),
        grow: true,
        size: 200,
      },
      {
        accessorKey: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.PHONE,
        Header: ({ column }: any) => renderHeaderWithTooltip(column),
        header: t('ADMIN.USERS_TAB.PHONE_#'),
        Cell: ({ renderedCellValue }: any) => (
          <PhoneCell>{renderedCellValue ?? '-'}</PhoneCell>
        ),
        grow: true,
        size: 120,
      },
      {
        accessorKey: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.TITLE,
        Header: ({ column }: any) => renderHeaderWithTooltip(column),
        header: t('ADMIN.USERS_TAB.TITLE'),
        Cell: ({ renderedCellValue }: any) => (
          <TitleCell>{renderedCellValue}</TitleCell>
        ),
        grow: true,
      },
      {
        accessorKey: AdminConstants.USERS.USERS_TABLE_HEADERS_ID.ROLE,
        Header: ({ column }: any) => renderHeaderWithTooltip(column),
        header: t('ADMIN.USERS_TAB.ROLE'),
        Cell: ({ renderedCellValue }: any) => (
          <RoleCell>{renderedCellValue}</RoleCell>
        ),
        grow: true,
        size: 140,
      },
    ],
    [],
  );

  return (
    <>
      <Box>
        {networkErrorState ? (
          <DataNotFoundDiv>{t('NO_DATA_FOUND')}</DataNotFoundDiv>
        ) : (
          <ServerSideDataGrid
            columns={columns}
            fetchDataFn={fetchData}
            fetchDataFnQueryKeyName={
              fetchDataFnQueryKeyName + fetchUsersDataFnQueryKeyName
            }
            dataFetchSize={AdminConstants.USERS.USER_TABLE_DATA_LIMIT}
            enableRowActions={true}
            enableRowSelection={false}
            enableSortingRemoval={false}
            enableSelectAll={false}
            enableSorting={false} // Disable sort icons from column header
            totalRowsCount={totalRowCount}
            getRowId={(row, index) => row.userId}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                setUsersTableState(
                  produce(draft => {
                    draft.currentClickedUserId = row.id;
                  }),
                );

                !isLoading && !isKebabMenuOpen
                  ? navigate(viewUserProfileNavigationURL(row.id), {
                      state: { isUserActive: isActiveUsersTabSelected },
                    })
                  : null;
              },
              onMouseEnter: () => {
                if (disableHoverEffect) return;
                setUsersTableState(
                  produce(draft => {
                    draft.hoveredRow = row.id;
                  }),
                );
              },
              onMouseLeave: () => {
                if (disableHoverEffect) return;
                setUsersTableState(
                  produce(draft => {
                    draft.hoveredRow = ' ';
                  }),
                );
              },
              sx: {
                minHeight: 73,
              },
            })}
            state={{ isLoading: isLoading || shouldReloadUsersTableGrid }}
            isDataLoading={isLoading || shouldReloadUsersTableGrid}
            showBottomProgressBar={false}
            muiCircularProgressProps={{
              color: 'secondary',
              thickness: 5,
              size: 55,
            }}
            muiSkeletonProps={{
              animation: 'pulse',
              height: 28,
            }}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                size: 150,
                grow: false,
                header: '',
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                backgroundColor:
                  APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY,
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                color: APP.PALETTE.GREY.GRAY_COLUMN,
                fontSize: APP.FONT.FONT_SIZE.BODY2,
                fontWeight: APP.FONT.FONT_WEIGHT.BODY1,
                backgroundColor:
                  APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY,
              },
            }}
            renderRowActions={({ row, table }) => renderRowActionsSection(row)}
          />
        )}
      </Box>
      {showMarkAsActiveOrInactiveConfirmationDialog &&
        renderMarkAsActiveOrInactiveConfirmationDialog()}
    </>
  );
};

export default UsersTable;
