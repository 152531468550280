import { ButtonStyled } from '@components/dialog/form-dialog/FormDialogStyle';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { ErrorTextStyled } from 'src/styles/common/CommonStyles';
import { APP } from 'src/styles/variables';

export const QueuePUBucketSettingsDialogContentContainer = styled.div`
  position: relative;
`;

export const MainLoaderContainer = styled.div<{ height: string }>`
  width: 424px;
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -35px;
  left: 83px;
`;

export const DistributeAllContainer = styled.div``;

export const DistributeAllLabel = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.FONT_SIZE_17PX};
  font-weight: ${APP.FONT.FONT_WEIGHT.H6};
  font-family: ${APP.FONT.FONT_FAMILY.MEDIUM};
  margin-bottom: 0.5rem;
`;

export const DistributeAllButtonText = styled('div')`
  font-size: ${APP.FONT.FONT_SIZE.SUBTITLE1};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  /* margin-right: 5rem; */
`;

export const DistributeAllButton = styled(ButtonStyled)<{ disabled?: boolean }>`
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  color: ${APP.PALETTE.COMMON.WHITE};
  width: 100%;
  font-family: ${APP.FONT.FONT_FAMILY.BOLD};
  &:hover {
    box-shadow: ${APP.PALETTE.GREY.BOX_SHADOW.GRAY};
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &.Mui-disabled {
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${APP.PALETTE.COMMON.WHITE};
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
  }
`;

export const InputWrapperDiv = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export const SettingsErrorTextStyled = styled(ErrorTextStyled)`
  margin-top: 0.5rem;
`;

export const DividerStyled = styled(Divider)`
  margin: 1.5rem 0 1rem;
`;

export const DialogContentLoaderContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
