import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { produce } from 'immer';
import {
  AvatarStyled,
  ButtonHoverStyled,
  CursorPointerRowDataDiv,
  DataNotFoundDiv,
  LoadingGridForProfilePic,
  UserCellImageDivStyled,
} from './QueueAssignmentsByUserStyle';
import {
  UserCellContentDiv,
  UserCellNameTypography,
  UserCellStateTypography,
  UserCellWrapperDiv,
} from '../../../dashboard/DashboardStyle';
import { useNavigate } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { t } from 'i18next';
import {
  GetTaskAssignmentsSummaryByUserParams,
  GetTaskAssignmentsSummaryByUserRow,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { getTaskAssignmentsSummaryByUser } from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import { APP } from 'src/styles/variables';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { RootState } from 'src/store/Store';
import { ERROR_CONSTANTS } from '@constants/global-constants/constants';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import Loader from '@components/loaders/Loader';
import {
  IQueryParamsList,
  setURLWithParams,
} from '@utils/SetURLWithParamsUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProfilePicsUrlsWithLoaderFlagById,
  setQueueSearchAndFilterOptions,
} from '@modules/home/state/queue/QueueSlice';
import {
  FormattedSearchAndFilterOptionsType,
  ProfilePicRefType,
} from 'src/types/Types';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import { mapStateNsaLabel } from '@modules/home/utils/Utils';
import axios, { CancelTokenSource } from 'axios';

interface IUsersListItem extends GetTaskAssignmentsSummaryByUserRow {
  profilePicUrl?: string;
}

interface IQueueAssignmentsByUserState {
  hoveredRow: string | null;
  isUsersListLoading: boolean;
  totalUsersCount: number;
  networkErrorState: boolean;
  userDataRows: IUsersListItem[];
}

const initialQueueAssignmentsByUser: IQueueAssignmentsByUserState = {
  isUsersListLoading: false,
  hoveredRow: null,
  totalUsersCount: 0,
  networkErrorState: false,
  userDataRows: [],
};

const QueueAssignmentsByUser = (): ReactElement => {
  const [queueAssignmentsByUserState, setQueueAssignmentsByUserState] =
    useState<IQueueAssignmentsByUserState>(initialQueueAssignmentsByUser);

  const {
    isUsersListLoading,
    hoveredRow,
    totalUsersCount,
    networkErrorState,
    userDataRows,
  } = queueAssignmentsByUserState;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});

  const {
    fetchDataFnQueryKeyNameForAssignmentsByUsers,
    reloadQueueTableGrid,
    profilePicsUrlsWithLoaderFlagById,
  } = useSelector((state: RootState) => state.queue);

  const { queueSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.queue,
  );

  const navigateToUserSettingsScreen = () => {
    const userSettingsScreenUrl =
      PrivateNavigationRoutes.Admins.ChildRoutes.Users.ChildRoutes.UsersSettings.FullPath.replace(
        ':userId',
        hoveredRow ?? '',
      );

    navigate(userSettingsScreenUrl);
  };

  // API section starts

  const getProfilePictureById = async (
    userId: string,
    profilePictureId: string,
  ) => {
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const cancelTokenSourceForSummaryByUser = useRef<CancelTokenSource | null>(
    null,
  );

  const fetchTaskAssignmentsSummaryByUser = async (
    pageParam: number,
    fetchSize: number,
  ) => {
    cancelTokenSourceForSummaryByUser.current = axios.CancelToken.source();

    setQueueAssignmentsByUserState(
      produce(draft => {
        draft.isUsersListLoading = true;
      }),
    );
    try {
      const payloadData: GetTaskAssignmentsSummaryByUserParams = {
        limit: fetchSize,
        offset: pageParam * fetchSize,
      };
      if (
        typeof queueSearchAndFilterOptions?.assignedUserId === 'string' &&
        queueSearchAndFilterOptions?.assignedUserId?.length > 2
      ) {
        payloadData.userId = queueSearchAndFilterOptions?.assignedUserId;
      }

      if (
        queueSearchAndFilterOptions?.nsaState ===
        AdminConstants.USERS.NSA.toLowerCase()
      ) {
        payloadData.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
      } else if (
        typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
        queueSearchAndFilterOptions?.nsaState === 'state'
      ) {
        payloadData.stateNsa =
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE;
      } else if (
        typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
        queueSearchAndFilterOptions?.nsaState !== 'state' &&
        queueSearchAndFilterOptions?.nsaState !==
          AdminConstants.USERS.NSA.toLowerCase() &&
        queueSearchAndFilterOptions?.nsaState !== ''
      ) {
        payloadData.stateId = queueSearchAndFilterOptions.nsaState;
        payloadData.stateNsa =
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE;
      }

      const getTaskAssignmentsSummaryByUserResponse =
        await getTaskAssignmentsSummaryByUser(
          payloadData,
          cancelTokenSourceForSummaryByUser.current,
        );

      const usersData: IUsersListItem[] =
        getTaskAssignmentsSummaryByUserResponse?.data?.rows ?? [];

      setQueueAssignmentsByUserState(
        produce(draft => {
          draft.userDataRows = usersData;
        }),
      );

      setQueueAssignmentsByUserState(
        produce(draft => {
          draft.totalUsersCount =
            getTaskAssignmentsSummaryByUserResponse?.data?.count ?? 0;
          draft.networkErrorState = false;
        }),
      );

      return usersData;
    } catch (errorResponse: any) {
      const errMessage = getErrorMessage(errorResponse);
      const isNetworkError =
        errorResponse.code === ERROR_CONSTANTS.ERR_NETWORK ||
        errorResponse.statusCode === 500 ||
        (errorResponse.statusCode === 400 && pageParam === 0);

      if (isNetworkError) {
        setQueueAssignmentsByUserState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueAssignmentsByUserState(
        produce(draft => {
          draft.isUsersListLoading = false;
        }),
      );
      cancelTokenSourceForSummaryByUser.current = null;
    }
  };

  // API section ends

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForSummaryByUser.current) {
        cancelTokenSourceForSummaryByUser.current.cancel();
      }
    };
  }, []);

  const handlePriorityOrTotalButtonClick = (priority: string, row?: any) => {
    const TempStoredSearchAndFilterOptionsInLocal = localStorage.getItem(
      AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.SEARCH_AND_FILTER_OPTIONS,
    );
    const TempParsedSearchAndFilterOptionsFromLocal =
      TempStoredSearchAndFilterOptionsInLocal
        ? JSON.parse(TempStoredSearchAndFilterOptionsInLocal)
        : {};

    const queryParamsList = [];

    if (
      priority !== AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.TOTAL
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .TASK_PRIORITY,
        queryParamValue: priority,
      });
    }

    if (row?.id) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .ASSIGNED_USER,
        queryParamValue: row?.id,
      });
    }

    if (
      TempParsedSearchAndFilterOptionsFromLocal?.assignedUserId &&
      typeof TempParsedSearchAndFilterOptionsFromLocal?.assignedUserId ===
        'string'
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .ASSIGNED_USER,
        queryParamValue:
          TempParsedSearchAndFilterOptionsFromLocal.assignedUserId,
      });
    }

    if (
      TempParsedSearchAndFilterOptionsFromLocal?.nsaState ===
      AdminConstants.USERS.NSA.toLowerCase()
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: TempParsedSearchAndFilterOptionsFromLocal.nsaState,
      });
    } else if (
      typeof TempParsedSearchAndFilterOptionsFromLocal?.nsaState === 'string' &&
      TempParsedSearchAndFilterOptionsFromLocal?.nsaState ===
        AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: TempParsedSearchAndFilterOptionsFromLocal.nsaState,
      });
    } else if (
      typeof TempParsedSearchAndFilterOptionsFromLocal?.nsaState === 'string' &&
      TempParsedSearchAndFilterOptionsFromLocal?.nsaState !==
        AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE &&
      TempParsedSearchAndFilterOptionsFromLocal?.nsaState !==
        AdminConstants.USERS.NSA.toLowerCase() &&
      TempParsedSearchAndFilterOptionsFromLocal?.nsaState !== ''
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: TempParsedSearchAndFilterOptionsFromLocal.nsaState,
      });
    }
    const formattedSearchAndFilterOptions: { [key: string]: string | boolean } =
      queryParamsList.reduce((acc, queryParam) => {
        acc[queryParam.queryParamName] = queryParam.queryParamValue;
        return acc;
      }, {} as FormattedSearchAndFilterOptionsType);

    dispatch(
      setQueueSearchAndFilterOptions({
        data: formattedSearchAndFilterOptions,
      }),
    );

    const newUrl = setURLWithParams(
      null,
      queryParamsList,
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
        .FullPath,
    );

    localStorage.removeItem(
      AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.SEARCH_AND_FILTER_OPTIONS,
    );

    navigate(newUrl);
  };

  useEffect(() => {
    localStorage.setItem(
      AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.SEARCH_AND_FILTER_OPTIONS,
      JSON.stringify(queueSearchAndFilterOptions),
    );
  }, [queueSearchAndFilterOptions]);

  const renderCellValue = (value: any, priority: any = '', row?: any) => {
    return (
      <div>
        <CursorPointerRowDataDiv
          onClick={() => handlePriorityOrTotalButtonClick(priority, row)}
        >
          {value ?? '0'}
        </CursorPointerRowDataDiv>
      </div>
    );
  };

  const fetchProfilePictures = async () => {
    for (const userData of userDataRows) {
      const { userId = '', userProfilePicId = '' } = userData;
      if (
        userId?.length > 0 &&
        userProfilePicId &&
        userProfilePicId?.length > 0 &&
        !fetchedProfilePicRef.current[userId]
      ) {
        fetchedProfilePicRef.current[userId] = true;
        await getProfilePictureById(userId, userProfilePicId);
      }
    }
  };

  useEffect(() => {
    fetchProfilePictures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataRows]);

  const renderUserColumnValue = (row: any) => {
    let secondaryText = row?.userStateNsa
      ? mapStateNsaLabel(row.userStateNsa)
      : '';

    if (row?.isOnPto) {
      secondaryText +=
        secondaryText.length > 1
          ? `| ${t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ON_PTO')}`
          : t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ON_PTO');
    }
    return (
      <UserCellWrapperDiv>
        <UserCellImageDivStyled>
          {profilePicsUrlsWithLoaderFlagById[row?.userId]
            ?.isProfilePicLoading ? (
            <LoadingGridForProfilePic>
              <Loader size={20} />
            </LoadingGridForProfilePic>
          ) : (
            <AvatarStyled
              alt={row?.userFullName ?? ''}
              src={
                profilePicsUrlsWithLoaderFlagById[row?.userId]?.profilePicUrl ??
                ''
              }
            />
          )}
        </UserCellImageDivStyled>
        <UserCellContentDiv>
          <UserCellNameTypography>{row?.userFullName}</UserCellNameTypography>
          <UserCellStateTypography>{secondaryText}</UserCellStateTypography>
        </UserCellContentDiv>
      </UserCellWrapperDiv>
    );
  };

  const columnsData = useMemo(
    () => [
      {
        id: AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.USER,
        header: t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER_TABLE_COLUMNS.USER',
        ),
        accessorFn: (row: IUsersListItem) => {
          return {
            userStateNsa: row?.userStateNsa ?? '',
            isOnPto: row?.isOnPto,
            userFullName: row?.userFullName ?? '',
            userId: row?.userId ?? '',
          };
        },
        Cell: ({ renderedCellValue }: { renderedCellValue: any }) => {
          const row = renderedCellValue;
          return renderUserColumnValue(row);
        },
        size: 200,
      },
      {
        accessorKey:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.TOTAL,
        header: t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER_TABLE_COLUMNS.TOTAL',
        ),
        Cell: ({ renderedCellValue, row }: any) =>
          renderCellValue(
            renderedCellValue,
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.TOTAL,
            row,
          ),
        grow: false,
      },
      {
        accessorKey:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.HIGH,
        header: t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER_TABLE_COLUMNS.HIGH',
        ),
        Cell: ({ renderedCellValue, row }: any) =>
          renderCellValue(
            renderedCellValue,
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.HIGH,
            row,
          ),
        grow: false,
      },
      {
        accessorKey:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.MEDIUM,
        header: t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER_TABLE_COLUMNS.MEDIUM',
        ),
        Cell: ({ renderedCellValue, row }: any) =>
          renderCellValue(
            renderedCellValue,
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.MEDIUM,
            row,
          ),
        grow: false,
      },
      {
        accessorKey:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.LOW,
        header: t(
          'ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER_TABLE_COLUMNS.LOW',
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <CursorPointerRowDataDiv
            onClick={() =>
              handlePriorityOrTotalButtonClick(
                AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.LOW,
                row,
              )
            }
          >
            {renderedCellValue}
          </CursorPointerRowDataDiv>
        ),
        grow: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profilePicsUrlsWithLoaderFlagById],
  );

  const handleViewQueueButtonClick = (assignedUserId: string | null) => {
    if (assignedUserId) {
      const result = [
        {
          queryParamName: AdminConstants.QUEUE.ASSIGNED_USER_ID,
          queryParamValue: assignedUserId,
        },
      ];

      dispatch(
        setQueueSearchAndFilterOptions({
          data: {
            assignedUserId: assignedUserId,
          },
        }),
      );

      const finalURLWithParams = setURLWithParams(
        null,
        result as IQueryParamsList[],
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .FullPath,
      );

      navigate(finalURLWithParams, {
        state: { queueSelected: true },
      });
    }
  };

  const renderRowActionsSection = (row: any) => (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '2rem' }}>
      {hoveredRow === row.id && (
        <>
          <ButtonHoverStyled
            onClick={() => handleViewQueueButtonClick(hoveredRow)}
          >
            {t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.VIEW_QUEUE')}
          </ButtonHoverStyled>
          <ButtonHoverStyled onClick={() => navigateToUserSettingsScreen()}>
            {t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.PROFILE')}
          </ButtonHoverStyled>
        </>
      )}
    </Box>
  );

  return (
    <Box>
      {networkErrorState ? (
        <DataNotFoundDiv>{t('NO_DATA_FOUND')}</DataNotFoundDiv>
      ) : (
        <ServerSideDataGrid
          columns={columnsData}
          fetchDataFn={fetchTaskAssignmentsSummaryByUser}
          fetchDataFnQueryKeyName={fetchDataFnQueryKeyNameForAssignmentsByUsers}
          dataFetchSize={
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
          }
          enableRowActions={true}
          enableRowSelection={false}
          enableSortingRemoval={false}
          enableSelectAll={false}
          enableSorting={false} // Disable sort icons from column header
          totalRowsCount={totalUsersCount}
          getRowId={(row, index) => row?.userId ?? index}
          muiTableBodyCellProps={{
            sx: theme => ({
              cursor: 'auto',
            }),
          }}
          muiTableBodyRowProps={({ row }) => ({
            onMouseEnter: () => {
              setQueueAssignmentsByUserState(
                produce(draft => {
                  draft.hoveredRow = row.id;
                }),
              );
            },
            onMouseLeave: () => {
              setQueueAssignmentsByUserState(
                produce(draft => {
                  draft.hoveredRow = null;
                }),
              );
            },
          })}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              size: 300,
              grow: false,
              header: '',
            },
          }}
          muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
          }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          muiTableContainerProps={{
            sx: { maxHeight: 'calc(100vh - 5rem)' },
          }}
          positionActionsColumn="last"
          state={{
            isLoading: isUsersListLoading || reloadQueueTableGrid,
          }}
          isDataLoading={isUsersListLoading || reloadQueueTableGrid}
          showBottomProgressBar={false}
          muiTableHeadRowProps={{
            sx: {
              backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY,
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              color: APP.PALETTE.GREY.GRAY_COLUMN,
              fontSize: APP.FONT.FONT_SIZE.BODY2,
              fontWeight: APP.FONT.FONT_WEIGHT.BODY1,
              backgroundColor: APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.SECONDARY,
            },
          }}
          renderRowActions={({ row }) => renderRowActionsSection(row)}
        />
      )}
    </Box>
  );
};

export default QueueAssignmentsByUser;
