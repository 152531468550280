import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useMemo,
  memo,
  ChangeEvent,
} from 'react';
import { Autocomplete, Avatar, Popper } from '@mui/material';
import {
  LoadingGridForDropdownSelectInput,
  LoadingGridForSearchableSelectInput,
  TextFieldWrapperStyled,
} from './SearchableSelectInputStyle';
import { AssignedUser } from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import Loader from '@components/loaders/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { produce } from 'immer';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from '@utils/ErrorUtils';
import { t } from 'i18next';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import { setProfilePicsUrlsWithLoaderFlagById } from '@modules/home/state/queue/QueueSlice';
import { getReassignUsersListByTaskId } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { ProfilePicRefType } from 'src/types/Types';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

interface ISearchableSelectInputProps {
  options: AssignedUser[] | [];
  defaultOption?: AssignedUser;
  showProfilePic?: boolean;
  handlePopOverOpenClose?: (isModalOpen?: boolean) => void;
  openDropdown?: boolean;
  size?: 'small' | 'medium';
  clearIcon?: boolean;
  disabled?: boolean;
  handleOnChange?: (value: string) => void;
  width?: string;
  isDefaultOptionRequired?: boolean;
  handleOptionSelectChange?: (value: any) => void;
  shouldLoadDefaultProfilePic?: boolean;
  shouldFetchUsersOptionsListByTaskId?: boolean;
  shouldLoadProfilePicsForBulkReAssign?: boolean;
  taskId?: string;
  fetchedProfilePicRef: React.MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
}

type SearchableSelectInputStateType = {
  isReassignUsersListByTaskIdLoading: boolean;
  reassignUsersListByTaskId: AssignedUser[];
};

const initialSearchableSelectInputState: SearchableSelectInputStateType = {
  isReassignUsersListByTaskIdLoading: true,
  reassignUsersListByTaskId: [],
};

const SearchableSelectInput = (
  props: ISearchableSelectInputProps,
): ReactElement => {
  const {
    options: originalOptions,
    showProfilePic = true,
    defaultOption,
    handlePopOverOpenClose,
    handleOnChange,
    openDropdown,
    size = 'small',
    clearIcon = false,
    disabled = false,
    width,
    isDefaultOptionRequired = true,
    handleOptionSelectChange,
    shouldLoadDefaultProfilePic = false,
    shouldFetchUsersOptionsListByTaskId = false,
    shouldLoadProfilePicsForBulkReAssign = false,
    taskId = '',
    fetchedProfilePicRef,
    setFetchProfilePicRef,
  } = props;

  const dispatch = useDispatch();

  const { profilePicsUrlsWithLoaderFlagById } = useSelector(
    (state: RootState) => state.queue,
  );

  const [searchableSelectInputState, setSearchableSelectInputState] =
    useState<SearchableSelectInputStateType>(initialSearchableSelectInputState);

  const { isReassignUsersListByTaskIdLoading, reassignUsersListByTaskId } =
    searchableSelectInputState;

  const UnassignedObj = {
    contactEmail: null,
    email: null,
    id: '1',
    name: 'Unassigned',
    profilePicId: null,
  };

  const UnassignObj = {
    contactEmail: null,
    email: null,
    id: null,
    name: AdminConstants.QUEUE.UN_ASSIGN,
    profilePicId: null,
  };

  const defaultEmptyValue = {
    contactEmail: null,
    email: '',
    id: '-1',
    name: '',
    profilePicId: null,
  };

  // API region starts

  const fetchProfilePicById = async (
    userId: string,
    profilePictureId: string,
  ) => {
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const fetchReAssignUsersListByTaskId = async () => {
    try {
      const reassignUsersListByTaskIdResponse =
        await getReassignUsersListByTaskId(taskId);
      setSearchableSelectInputState(
        produce(draft => {
          draft.reassignUsersListByTaskId =
            reassignUsersListByTaskIdResponse?.data?.rows ?? [];
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setSearchableSelectInputState(
        produce(draft => {
          draft.isReassignUsersListByTaskIdLoading = false;
        }),
      );
    }
  };

  // API region ends

  // state
  const defaultValue = defaultOption || UnassignedObj;
  const [value, setValue] = useState<any>(
    isDefaultOptionRequired ? defaultValue || null : defaultEmptyValue,
  );

  // memoize options
  const options = useMemo(() => {
    if (
      isReassignUsersListByTaskIdLoading &&
      shouldFetchUsersOptionsListByTaskId &&
      !shouldLoadProfilePicsForBulkReAssign
    ) {
      return [];
    }
    const optionsWithUnassignOption = shouldFetchUsersOptionsListByTaskId
      ? reassignUsersListByTaskId
      : originalOptions;
    if (
      value?.name?.toLowerCase() !== UnassignedObj.name.toLowerCase() &&
      value?.name?.toLowerCase() !== UnassignObj.name.toLowerCase()
    ) {
      return [UnassignObj, ...optionsWithUnassignOption];
    }

    return optionsWithUnassignOption;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    originalOptions,
    reassignUsersListByTaskId,
    shouldFetchUsersOptionsListByTaskId,
    defaultValue,
    isReassignUsersListByTaskIdLoading,
  ]);

  useEffect(() => {
    openDropdown &&
    shouldFetchUsersOptionsListByTaskId &&
    reassignUsersListByTaskId.length === 0
      ? fetchReAssignUsersListByTaskId()
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown]);

  const getProfilePicsOfReassignUsers = async (usersList: AssignedUser[]) => {
    for (const user of usersList) {
      if (
        user?.id &&
        user?.profilePicId &&
        !profilePicsUrlsWithLoaderFlagById[user.id] &&
        !fetchedProfilePicRef.current[user.id]
      ) {
        setFetchProfilePicRef(user.id);
        await fetchProfilePicById(user.id, user.profilePicId);
      }
    }
  };

  useEffect(() => {
    if (reassignUsersListByTaskId.length > 0) {
      getProfilePicsOfReassignUsers(reassignUsersListByTaskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reassignUsersListByTaskId]);

  useEffect(() => {
    if (shouldLoadProfilePicsForBulkReAssign && options.length > 0) {
      getProfilePicsOfReassignUsers(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadProfilePicsForBulkReAssign, options]);

  const init = async () => {
    if (
      shouldLoadDefaultProfilePic &&
      defaultValue?.id &&
      defaultValue?.profilePicId &&
      !fetchedProfilePicRef.current[defaultValue.id]
    ) {
      setFetchProfilePicRef(defaultValue.id);
      await fetchProfilePicById(defaultValue.id, defaultValue.profilePicId);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);

  // functions
  const CustomInput = ({ ...params }: any) => {
    const selectedUser = shouldFetchUsersOptionsListByTaskId
      ? value
      : options.find(
          (option: { id: string | number | null }) => option.id === value?.id,
        );

    const isProfilePicIdAvailable =
      shouldFetchUsersOptionsListByTaskId && selectedUser?.profilePicId
        ? profilePicsUrlsWithLoaderFlagById[selectedUser?.id ?? '']
            ?.isProfilePicLoading
        : false;
    const profilePic = selectedUser
      ? profilePicsUrlsWithLoaderFlagById[selectedUser.id]?.profilePicUrl
      : '';

    const customOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.trim();
      const formattedTarget = { ...event.target, value: inputValue };
      params.inputProps.onChange({
        ...event,
        target: formattedTarget,
      });
    };

    return (
      <TextFieldWrapperStyled
        {...params}
        onChange={customOnChange}
        inputRef={inputRef}
        value={value || ''}
        InputProps={{
          ...params.InputProps,
          startAdornment:
            showProfilePic && profilePic ? (
              <Avatar
                src={profilePic}
                alt={value ? value.name : ''}
                sx={{ width: 25, height: 25 }}
              />
            ) : isProfilePicIdAvailable ? (
              <LoadingGridForSearchableSelectInput>
                <Loader size={10} />
              </LoadingGridForSearchableSelectInput>
            ) : (
              <Avatar
                src={profilePic}
                alt={value ? value.name : ''}
                sx={{ width: 25, height: 25 }}
              />
            ),
        }}
      />
    );
  };

  useEffect(() => {
    if (value === null && inputRef.current) {
      inputRef.current.focus();
    }

    if (!openDropdown && value === null) {
      isDefaultOptionRequired
        ? setValue(defaultValue)
        : setValue(defaultEmptyValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, openDropdown, defaultValue, isDefaultOptionRequired]);

  const handleOpenClosePopOver = (isModalOpen: boolean) => {
    handlePopOverOpenClose && handlePopOverOpenClose(isModalOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const CustomPopper = (props: any) => (
    <Popper
      {...props}
      disablePortal={false}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ]}
    />
  );

  const handleChange = (newValue: any) => {
    if (newValue?.name?.toLowerCase() === UnassignObj.name.toLowerCase()) {
      setValue(UnassignedObj);
    } else {
      setValue(newValue);
    }
    handleOnChange?.(newValue);
    handleOptionSelectChange?.(newValue);
    setSearchableSelectInputState(
      produce(draft => {
        draft.reassignUsersListByTaskId = [];
      }),
    );
  };
  return (
    <Autocomplete
      {...(openDropdown != undefined ? { open: openDropdown } : {})}
      size={size}
      sx={{ width: width ?? '18.75rem' }}
      options={options}
      disabled={disabled}
      getOptionLabel={option => `${option.name} `}
      clearIcon={clearIcon}
      loading={
        shouldFetchUsersOptionsListByTaskId
          ? isReassignUsersListByTaskIdLoading
          : false
      }
      defaultValue={defaultOption?.name || value}
      renderOption={(rest, option) => {
        const isProfilePicIdAvailable = option?.profilePicId;
        const isProfilePicLoading =
          shouldFetchUsersOptionsListByTaskId ||
          shouldLoadProfilePicsForBulkReAssign
            ? profilePicsUrlsWithLoaderFlagById[option.id]?.isProfilePicLoading
            : isProfilePicIdAvailable;
        const profilePic = option
          ? profilePicsUrlsWithLoaderFlagById[option.id]?.profilePicUrl
          : '';
        return (
          <li {...rest} key={option.id}>
            {showProfilePic && profilePic ? (
              <Avatar
                src={profilePic}
                alt={option.name}
                sx={{ marginRight: 1 }}
              />
            ) : isProfilePicLoading ? (
              <LoadingGridForDropdownSelectInput>
                <Loader size={20} />
              </LoadingGridForDropdownSelectInput>
            ) : (
              <Avatar
                src={profilePic}
                alt={option.name}
                sx={{ marginRight: 1 }}
              />
            )}
            {option.name}
          </li>
        );
      }}
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      renderInput={params => <CustomInput {...params} />}
      onOpen={() => {
        handleOpenClosePopOver(true);
      }}
      onClose={() => {
        handleOpenClosePopOver(false);
      }}
      isOptionEqualToValue={(option, val) => option?.id === option?.id}
      PopperComponent={CustomPopper}
    />
  );
};

export default memo(SearchableSelectInput);
