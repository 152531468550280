// import { EmailsDummyData } from '@modules/home/main/admin/emails/EmailsDummyData';
import ServiceRequest from 'src/services/ServiceRequest';
import {
  getAllEmailSettingsURL,
  getAllEmailsUrl,
  getEmailDetailsByIdUrl,
  getEmailRecipientsByIdUrl,
  getEmailSettingsDetailURL,
  getEmailThreadByIdUrl,
  sendEmailReplyAllByIdUrl,
  sendEmailReplyByIdUrl,
  updateEmailSettingsDetailURL,
  updateEmailSubscriptionUrl,
} from './EmailApiEndPoints';
import {
  GetAllEmailQueryParams,
  GetAllEmailSettingsParams,
  GetAllEmailSettingsResponseData,
  GetAllEmailsResDto,
  GetEmailDetailsByIdResDto,
  GetEmailRecipientsByIdResDto,
  GetEmailRecipientsQueryParams,
  GetEmailSettingsDetailParams,
  GetEmailSettingsDetailResponseData,
  GetEmailThreadByIdResDto,
  SendEmailReplyAllByIdParams,
  SendEmailReplyByIdParams,
  SendEmailReplyByIdResDto,
  UpdateBulkEmailSettingsDetailParams,
  UpdateEmailSettingsDetailParams,
  UpdateEmailSettingsDetailResponseData,
  UpdateEmailSubscriptionParams,
  UpdateEmailSubscriptionResDto,
} from './EmailServiceMapper';
import {
  EmailReplyTypes,
  MultiPartFormDataKeys,
} from '@constants/global-constants/constants';
import { CancelTokenSource } from 'axios';

export const fetchAllEmailSettingsList = async (
  data?: GetAllEmailSettingsParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllEmailSettingsResponseData> => {
  return await ServiceRequest.get(
    getAllEmailSettingsURL,
    { payload: data },
    cancelTokenSource,
  );
};

export const fetchEmailSettingsDetail = async (
  data: GetEmailSettingsDetailParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetEmailSettingsDetailResponseData> => {
  const payload = undefined;
  const formedURL = getEmailSettingsDetailURL + '/' + data;
  return await ServiceRequest.get(formedURL, payload, cancelTokenSource);
};

export const updateEmailSettingsDetail = async (
  data: UpdateEmailSettingsDetailParams,
): Promise<UpdateEmailSettingsDetailResponseData> => {
  const { bodyParams, pathParam } = data;
  const formedURL = updateEmailSettingsDetailURL + '/' + pathParam;
  return await ServiceRequest.put(formedURL, {
    payload: bodyParams,
  });
};

export const updateBulkEmailSettingsDetailURL = async (
  data: UpdateBulkEmailSettingsDetailParams,
): Promise<UpdateEmailSettingsDetailResponseData> => {
  return await ServiceRequest.put(updateEmailSettingsDetailURL, {
    payload: data,
  });
};

// emails apis
export const getAllEmails = async (
  data: GetAllEmailQueryParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllEmailsResDto> => {
  return await ServiceRequest.get(
    getAllEmailsUrl,
    { payload: data },
    cancelTokenSource,
  );
};

export const getEmailDetailsById = async (
  id: string,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetEmailDetailsByIdResDto> => {
  const payload = undefined;
  return await ServiceRequest.get(
    getEmailDetailsByIdUrl.replace(':id', id),
    payload,
    cancelTokenSource,
  );
};

export const getEmailThreadById = async (
  id: string,
): Promise<GetEmailThreadByIdResDto> => {
  return await ServiceRequest.get(getEmailThreadByIdUrl.replace(':id', id));
};

export const getEmailRecipientsById = async (
  id: string,
  data: GetEmailRecipientsQueryParams,
): Promise<GetEmailRecipientsByIdResDto> => {
  return await ServiceRequest.get(
    getEmailRecipientsByIdUrl.replace(':id', id),
    { payload: data },
  );
};

export const sendEmailReplyById = async (
  data: SendEmailReplyByIdParams | SendEmailReplyAllByIdParams,
  emailReplyType: string,
): Promise<SendEmailReplyByIdResDto> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    if (key === MultiPartFormDataKeys.Attachments && Array.isArray(value)) {
      value.forEach(file => {
        formData.append(MultiPartFormDataKeys.Files, file);
      });
    } else if (Array.isArray(value)) {
      value.forEach(val => {
        formData.append(key, val);
      });
    } else if (typeof value === 'string') {
      formData.append(key, value);
    }
  }

  const url =
    emailReplyType === EmailReplyTypes.ReplyAll
      ? sendEmailReplyAllByIdUrl
      : sendEmailReplyByIdUrl;

  return await ServiceRequest.postDoc(url, {
    payload: formData,
  });
};

export const updateEmailSubscription = async (
  data: UpdateEmailSubscriptionParams,
): Promise<UpdateEmailSubscriptionResDto> => {
  return await ServiceRequest.post(updateEmailSubscriptionUrl, {
    payload: data,
  });
};
