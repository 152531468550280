import ServiceRequest from 'src/services/ServiceRequest';
import { DBQueryLoadUrl } from './DBQueryApiEndPoints';
import {
  updateDbQueryLoadParams,
  updateDbQueryLoadResponseData,
} from './DBQueryServiceMapper';

export const updateDBQueryLoad = async (
  data: updateDbQueryLoadParams,
): Promise<updateDbQueryLoadResponseData> => {
  return await ServiceRequest.post(DBQueryLoadUrl, {
    payload: data,
  });
};
