import TextButton from '@components/buttons/text-button/TextButton';
import SearchBar from '@components/search-bar/SearchBar';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { APP } from 'src/styles/variables';

export const TopSectionDiv = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.4rem;
`;

export const CloseIconImg = styled('img')`
  cursor: pointer;
`;

export const HeadingTypography = styled(Typography)`
  font-size: ${APP.FONT.FONT_SIZE.H5};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
`;

export const SearchDropdownSectionLabel = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: 1rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const FilterDropdownLabel = styled(Typography)`
  color: ${APP.PALETTE.TEXT.PRIMARY};
  font-size: 1rem;
  font-weight: ${APP.FONT.FONT_WEIGHT.CAPTION};
`;

export const SearchDropdownInputsWrapperDiv = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const SearchBarStyled = styled(SearchBar)`
  width: 100%;
`;

export const SectionDividerHR = styled('hr')`
  width: 100%;
  border: 1px solid ${APP.PALETTE.GREY.SHADE_300};
  margin: 0.85rem 0 !important;
`;

export const ActionButtonWrapperDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.8rem;
`;

export const ApplyFilterButton = styled(TextButton)`
  background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  border-radius: 0.625rem;

  &:hover {
    background-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
    border-color: ${APP.PALETTE.SECONDARY.BLUE_LIGHT};
  }
`;

export const ClearFilterButton = styled(TextButton)`
  border-color: ${APP.PALETTE.GREY.SEMI_GRAY};
  color: ${APP.PALETTE.TEXT.PRIMARY};
  border-radius: 0.625rem;
  background-color: ${APP.PALETTE.DASHBOARD.BACKGROUND_COLOR.PRIMARY};

  &:hover {
    color: ${APP.PALETTE.TEXT.PRIMARY};
    border-color: ${APP.PALETTE.GREY.SEMI_GRAY};
  }
`;

export const FilterLoaderDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 0;
`;

export const FilterOptionsWrapperDiv = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const SearchAndFilterSectionWrapperDiv = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SearchOptionsWrapperDiv = styled('div')`
  width: 100%;
`;
