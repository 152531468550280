import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { t } from 'i18next';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SettingsTabListItemType } from 'src/types/adminTypes';
import {
  BulkEditTextButton,
  FilterIconButton,
  MonitoringSubTabsContainer,
  OptionButtonContainer,
  RefreshIconDiv,
  RefreshIconStyle,
  SettingsTabMenuBarContainer,
  SubTabsContainer,
  TabFilterWrapper,
  TabItem,
  TabItemText,
  TabsContainer,
} from './SettingsStyle';
import { produce } from 'immer';
import { isProductionEnvironment } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import {
  setEmailSettingsSearchAndFilterValues,
  setIsEmailSettingsBulkEditButtonClicked,
  setIsEmailSettingsFilterButtonClicked,
} from '@modules/home/state/email-settings/EmailSettingsSlice';
import {
  SetIsEmailSettingsBulkEditButtonClickedPayloadType,
  SetIsEmailSettingsFilterButtonClickedPayloadType,
} from '@modules/home/state/email-settings/EmailSettingsTypes';
import Filter from '@assets/images/Filter.svg';
import { changeAutomationSettingsTableName } from '@modules/home/state/automation-settings/AutomationSettingsSlice';
import TabFilter from '@components/tabs/tab-filter/TabFilter';
import {
  changeDbQueryAndDataExceptionsTableName,
  changeEmailMonitoringTableName,
  clearAllRowsReloadingInprogress,
  clearReloadData,
  setDBQueryFilterValues,
  setEmailFilterValues,
  setIsDbQueryAndDataExceptionsFilterButtonClicked,
  setIsEmailsFilterButtonClicked,
} from '@modules/home/state/db-monitoring/DbMonitoringSlice';
import {
  SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType,
  SetIsEmailsFilterButtonClickedPayloadType,
} from '@modules/home/state/db-monitoring/DbMonitoringTypes';
import { IconButton, Tooltip } from '@mui/material';

type SettingsStateType = {
  settingsTabList: SettingsTabListItemType[];
  dbMonitoringRoute: boolean;
  selectedMonitoringSubTab: string;
};

const Settings = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isBulkEditNeeded } = useSelector(
    (reduxState: RootState) => reduxState.emailSettings,
  );

  const { isAutomationSettingsDataLoading } = useSelector(
    (reduxState: RootState) => reduxState.automationSettings,
  );

  const {
    isDbQueryAndDataExceptionsDataLoading,
    isEmailsDataLoading,
    rowIdsForReloadData: rowIdsForReload,
  } = useSelector((reduxState: RootState) => reduxState.dbMonitoring);

  const isEmailSettingsDetailsScreen = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return pathParts.includes(
      AdminConstants.EMAIL_SETTINGS.EMAIL_SETTING_DETAILS.PATHNAME,
    );
  };

  const dbMonitoringSubTabs = [
    {
      label: 'DBQuery & Data Exceptions',
      value: 'DBQuery and Data Exceptions Monitoring',
    },
    ...(!isProductionEnvironment()
      ? []
      : [{ label: 'Emails', value: 'Emails Monitoring' }]),
  ];

  const emailSettingsTabName = t('ADMIN.SETTINGS_TAB.EMAIL_SETTINGS.TAB_NAME');
  const automationSettingsTabName = t(
    'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.TAB_NAME',
  );
  const monitoringTabName = t('ADMIN.SETTINGS_TAB.MONITORING_TAB.TAB_NAME');

  const isEmailSettingsTab =
    location.pathname ===
    PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
      .EmailSettingsTable.FullPath;

  const isAutomationSettingsTab =
    location.pathname ===
    PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
      .AutomationSettings.FullPath;

  const settingsTabList: SettingsTabListItemType[] = [
    ...(!isProductionEnvironment()
      ? []
      : [
          {
            name: emailSettingsTabName,
            isActive:
              location.pathname ===
              PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
                .EmailSettingsTable.FullPath,
            navigationURL:
              PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
                .EmailSettingsTable.Path,
          },
        ]),
    {
      name: automationSettingsTabName,
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.Path,
    },
    {
      name: monitoringTabName,
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.Path,
    },
  ];

  const initialSettingsState: SettingsStateType = {
    settingsTabList: settingsTabList,
    dbMonitoringRoute: false,
    selectedMonitoringSubTab: dbMonitoringSubTabs[0].value,
  };

  const [settingsState, setSettingsState] = useState(initialSettingsState);

  const { selectedMonitoringSubTab } = settingsState;

  const isDbQueryMonitoringTab =
    location.pathname ===
    PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes.Monitoring
      .ChildRoutes.DBQueryAndDataExceptionsMonitoring.FullPath;

  const setCurrentActiveTab = (tabItemName: string) => {
    const newSettingsTabList = settingsTabList.map(
      (settingsTabListItem: SettingsTabListItemType) => ({
        ...settingsTabListItem,
        isActive:
          settingsTabListItem.name.toLowerCase() === tabItemName.toLowerCase(),
      }),
    );
    setSettingsState(
      produce(draft => {
        draft.settingsTabList = newSettingsTabList;
      }),
    );
  };

  const getSelectedTab = () => {
    if (location.pathname) {
      const splitValues = location.pathname.split('/');
      const lastRouteValue = splitValues[splitValues.length - 1];
      setSettingsState(
        produce(draft => {
          draft.selectedMonitoringSubTab =
            dbMonitoringSubTabs[
              lastRouteValue ===
              t(
                'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING_NAME',
              )
                ? 0
                : lastRouteValue ===
                  t(
                    'ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.EMAILS_MONITORING_NAME',
                  )
                ? 1
                : 0
            ].value;
        }),
      );
    }
  };

  useEffect(() => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .EmailSettingsTable.Path,
      )
    ) {
      setCurrentActiveTab(emailSettingsTabName);
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.Path,
      )
    ) {
      setCurrentActiveTab(automationSettingsTabName);
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.Path,
      )
    ) {
      setCurrentActiveTab(monitoringTabName);
    }
    getSelectedTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderSettingsTabListItem = (
    settingsTabListItem: SettingsTabListItemType,
    index: number,
  ) => (
    <TabItem
      key={`${settingsTabListItem.name}-${index}`}
      isActive={settingsTabListItem.isActive}
      onClick={() => {
        setCurrentActiveTab(settingsTabListItem.name);
        navigate(settingsTabListItem.navigationURL, {
          replace: true,
        });
      }}
    >
      <TabItemText isActive={settingsTabListItem.isActive}>
        {settingsTabListItem.name}
      </TabItemText>
    </TabItem>
  );

  useEffect(() => {
    const isDBQueryOrEmailsMonitoring =
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.ChildRoutes.DBQueryAndDataExceptionsMonitoring.Path,
      ) ||
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.ChildRoutes.EmailsMonitoring.Path,
      );

    const isEmailSettingsTable = location.pathname.includes(
      PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
        .EmailSettingsTable.Path,
    );

    rowIdsForReload.forEach(rowId => {
      dispatch(clearReloadData({ rowId }));
    });
    dispatch(clearAllRowsReloadingInprogress());
    if (!isDBQueryOrEmailsMonitoring) {
      dispatch(setDBQueryFilterValues({ data: {} }));
      dispatch(setEmailFilterValues({ data: {} }));
    } else if (!isEmailSettingsTable) {
      dispatch(setEmailSettingsSearchAndFilterValues({ data: {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleEmailSettingsFilterButtonClick = () => {
    const setIsEmailSettingsFilterButtonClickedPayload: SetIsEmailSettingsFilterButtonClickedPayloadType =
      {
        isEmailSettingsFilterButtonClickedPayload: true,
      };
    dispatch(
      setIsEmailSettingsFilterButtonClicked(
        setIsEmailSettingsFilterButtonClickedPayload,
      ),
    );
  };

  const handleMonitoringFilterButtonClick = () => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.ChildRoutes.DBQueryAndDataExceptionsMonitoring.Path,
      )
    ) {
      const setIsDbQueryAndDataExceptionsFilterButtonClickedPayload: SetIsDbQueryAndDataExceptionsFilterButtonClickedPayloadType =
        {
          isDbQueryAndDataExceptionsFilterButtonClickedPayload: true,
        };
      dispatch(
        setIsDbQueryAndDataExceptionsFilterButtonClicked(
          setIsDbQueryAndDataExceptionsFilterButtonClickedPayload,
        ),
      );
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .Monitoring.ChildRoutes.EmailsMonitoring.Path,
      )
    ) {
      {
        const setIsEmailsFilterButtonClickedPayload: SetIsEmailsFilterButtonClickedPayloadType =
          {
            isEmailsFilterButtonClickedPayload: true,
          };
        dispatch(
          setIsEmailsFilterButtonClicked(setIsEmailsFilterButtonClickedPayload),
        );
      }
    }
  };

  const handleEmailSettingsBulkEditButtonClick = () => {
    const setIsEmailSettingsBulkEditButtonClickedPayload: SetIsEmailSettingsBulkEditButtonClickedPayloadType =
      {
        isEmailSettingsBulkEditButtonClickedPayload: true,
      };
    dispatch(
      setIsEmailSettingsBulkEditButtonClicked(
        setIsEmailSettingsBulkEditButtonClickedPayload,
      ),
    );
  };

  const handleRefreshButtonClick = (currentTab: string) => {
    rowIdsForReload.forEach(rowId => {
      dispatch(clearReloadData({ rowId }));
    });
    dispatch(clearAllRowsReloadingInprogress());
    if (
      currentTab ===
      t(
        'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.TAB_NAME',
      )
    ) {
      dispatch(changeDbQueryAndDataExceptionsTableName());
    } else if (
      currentTab ===
      t('ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.TAB_NAME')
    ) {
      dispatch(changeEmailMonitoringTableName());
    } else if (
      currentTab === t('ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.TAB_NAME')
    ) {
      dispatch(changeAutomationSettingsTableName());
    }
  };

  const renderEmailSettingsTopBarRightSection = () => {
    return (
      <OptionButtonContainer>
        {!isBulkEditNeeded ? (
          <FilterIconButton onClick={handleEmailSettingsFilterButtonClick}>
            <img src={Filter} height={36} width={36} alt="..." />
          </FilterIconButton>
        ) : (
          <BulkEditTextButton
            variant="contained"
            onClick={handleEmailSettingsBulkEditButtonClick}
          >
            {t('PRIVATE.EMAILS.EDIT_EMAILS')}
          </BulkEditTextButton>
        )}
      </OptionButtonContainer>
    );
  };

  const renderMonitoringTopBarRightSection = () => {
    return (
      <MonitoringSubTabsContainer>
        <FilterIconButton onClick={handleMonitoringFilterButtonClick}>
          <img src={Filter} height={36} width={36} alt="filter" />
        </FilterIconButton>
      </MonitoringSubTabsContainer>
    );
  };

  const renderRefreshButton = () => {
    const isRefreshButtonDisabled =
      isAutomationSettingsDataLoading ||
      isDbQueryAndDataExceptionsDataLoading ||
      isEmailsDataLoading;
    const currentTabName = isDbQueryMonitoringTab
      ? t(
          'ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_AND_DATA_EXCEPTIONS_MONITORING.TAB_NAME',
        )
      : isAutomationSettingsTab
      ? t('ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.TAB_NAME')
      : t('ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.TAB_NAME');

    return (
      <Tooltip title={`${t('ADMIN.SETTINGS_TAB.MONITORING_TAB.REFRESH')}`}>
        <RefreshIconDiv>
          <IconButton
            size="large"
            disabled={isRefreshButtonDisabled}
            aria-label="Refresh"
            onClick={
              !isRefreshButtonDisabled
                ? () => handleRefreshButtonClick(currentTabName)
                : undefined
            }
          >
            <RefreshIconStyle />
          </IconButton>
        </RefreshIconDiv>
      </Tooltip>
    );
  };

  const handleTabChange = (data: string) => {
    data === t('ADMIN.SETTINGS_TAB.MONITORING_TAB.DB_QUERY_MONITORING.TAB_NAME')
      ? navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
            .Monitoring.ChildRoutes.DBQueryAndDataExceptionsMonitoring.FullPath,
        )
      : data ===
        t('ADMIN.SETTINGS_TAB.MONITORING_TAB.EMAILS_MONITORING.TAB_NAME')
      ? navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
            .Monitoring.ChildRoutes.EmailsMonitoring.FullPath,
        )
      : navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
            .Monitoring.ChildRoutes.DBQueryAndDataExceptionsMonitoring.FullPath,
        );

    rowIdsForReload.forEach(rowId => {
      dispatch(clearReloadData({ rowId }));
    });
    dispatch(clearAllRowsReloadingInprogress());
    dispatch(setDBQueryFilterValues({ data: {} }));
    dispatch(setEmailFilterValues({ data: {} }));
  };

  const renderSubMonitoringTabs = () => {
    return (
      <>
        {renderRefreshButton()}
        <SubTabsContainer>
          <TabFilterWrapper>
            <TabFilter
              filterOptions={dbMonitoringSubTabs}
              onSelectionChange={handleTabChange}
              defaultSelectedTab={selectedMonitoringSubTab}
            />
          </TabFilterWrapper>
        </SubTabsContainer>
        {renderMonitoringTopBarRightSection()}
      </>
    );
  };

  const renderSettingsTabsMenu = () => (
    <SettingsTabMenuBarContainer>
      <TabsContainer>
        {settingsState.settingsTabList.map((settingsTabListItem, index) =>
          renderSettingsTabListItem(settingsTabListItem, index),
        )}
      </TabsContainer>
      {isEmailSettingsTab ? renderEmailSettingsTopBarRightSection() : null}
      {isAutomationSettingsTab ? renderRefreshButton() : null}
    </SettingsTabMenuBarContainer>
  );

  const renderSelectedTabBody = () => <Outlet />;

  return (
    <>
      {!isEmailSettingsDetailsScreen() ? (
        <TopBarWrapperGrid
          height="5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderSettingsTabsMenu()}
          {location.pathname ===
            PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
              .Monitoring.ChildRoutes.DBQueryAndDataExceptionsMonitoring
              .FullPath ||
          location.pathname ===
            PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
              .Monitoring.ChildRoutes.EmailsMonitoring.FullPath
            ? renderSubMonitoringTabs()
            : null}
        </TopBarWrapperGrid>
      ) : null}
      {renderSelectedTabBody()}
    </>
  );
};

export default Settings;
