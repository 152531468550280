import React, { ReactElement, useEffect, useState } from 'react';
import { ListItem, Divider, ListItemText } from '@mui/material';
import {
  AvatarStyled,
  CurrentTaskCount,
  ListItemAvatarStyled,
  ListItemContainer,
  LoadingGridForProfilePic,
  PrimaryTextStyled,
  SecondaryTextStyled,
  StyledList,
} from './CustomUserDataCardStyle';
import { GetTasksCountSummaryByUsersForTaskCategoryIdRow } from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { produce } from 'immer';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import Loader from '@components/loaders/Loader';
import { setURLWithParams } from '@utils/SetURLWithParamsUtils';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useNavigate } from 'react-router-dom';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import {
  setProfilePicsUrlsWithLoaderFlagById,
  setQueueSearchAndFilterOptions,
} from '@modules/home/state/queue/QueueSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormattedSearchAndFilterOptionsType,
  ProfilePicRefType,
} from 'src/types/Types';
import { mapStateNsaLabel } from '@modules/home/utils/Utils';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import { RootState } from 'src/store/Store';

interface CustomUserDataCardProps {
  userData: GetTasksCountSummaryByUsersForTaskCategoryIdRow;
  taskCategoryId?: string;
  fetchedProfilePicRef: React.MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
}

interface ICustomUserDataCardState {
  secondaryText: string;
}

const initialCustomUserDataCardState = {
  secondaryText: '',
};

const CustomUserDataCard = (props: CustomUserDataCardProps): ReactElement => {
  const {
    userData,
    taskCategoryId,
    fetchedProfilePicRef,
    setFetchProfilePicRef,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profilePicsUrlsWithLoaderFlagById } = useSelector(
    (state: RootState) => state.queue,
  );

  const [customUserDataCardState, setCustomUserDataCardState] =
    useState<ICustomUserDataCardState>(initialCustomUserDataCardState);

  const handlePriorityButtonClick = () => {
    const queryParamsList = [];

    if (userData?.userId) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .ASSIGNED_USER,
        queryParamValue: userData.userId,
      });
    }

    if (taskCategoryId) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .TASK_CATEGORY,
        queryParamValue: taskCategoryId,
      });
    }

    const formattedSearchAndFilterOptions: { [key: string]: string | boolean } =
      queryParamsList.reduce((acc, queryParam) => {
        acc[queryParam.queryParamName] = queryParam.queryParamValue;
        return acc;
      }, {} as FormattedSearchAndFilterOptionsType);

    dispatch(
      setQueueSearchAndFilterOptions({
        data: formattedSearchAndFilterOptions,
      }),
    );

    const newUrl = setURLWithParams(
      null,
      queryParamsList,
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
        .FullPath,
    );
    navigate(newUrl);
  };

  // API section starts

  const getProfilePictureById = async (
    userId: string,
    profilePictureId: string,
  ) => {
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            setFetchProfilePicRef(userId);
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const fetchProfilePicture = async () => {
    const { userId = '', userProfilePicId = '' } = userData;
    if (
      userId?.length > 0 &&
      userProfilePicId &&
      userProfilePicId?.length > 0 &&
      !fetchedProfilePicRef.current[userId]
    ) {
      fetchedProfilePicRef.current[userId] = true;
      await getProfilePictureById(userId, userProfilePicId);
    }
  };

  // API section ends

  const init = async () => {
    await fetchProfilePicture();
  };

  useEffect(() => {
    let newSecondaryText = mapStateNsaLabel(userData?.userStateNsa);
    if (userData.isOnPto) {
      newSecondaryText +=
        newSecondaryText.length > 1
          ? ` | ${t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ON_PTO')}`
          : t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.ON_PTO');
    }

    if (userData.isTemp) {
      newSecondaryText +=
        newSecondaryText.length > 1 || userData.isOnPto
          ? ` | ${t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.TEMP')}`
          : t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.TEMP');
    }

    setCustomUserDataCardState(
      produce(draft => {
        draft.secondaryText = newSecondaryText;
      }),
    );

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledList disablePadding={true}>
      <ListItemContainer isOnPto={userData.isOnPto} isTemp={userData.isTemp}>
        <ListItem dense={true} alignItems="flex-start">
          <ListItemAvatarStyled>
            {profilePicsUrlsWithLoaderFlagById[userData?.userId]
              ?.isProfilePicLoading ? (
              <LoadingGridForProfilePic>
                <Loader size={30} />
              </LoadingGridForProfilePic>
            ) : (
              <AvatarStyled
                alt={userData?.userFullName ?? ''}
                src={
                  profilePicsUrlsWithLoaderFlagById[userData?.userId]
                    ?.profilePicUrl ?? ''
                }
              />
            )}
          </ListItemAvatarStyled>
          <ListItemText
            primary={
              <PrimaryTextStyled>
                {userData?.userFullName ?? ''}
              </PrimaryTextStyled>
            }
            secondary={
              <SecondaryTextStyled>
                {customUserDataCardState.secondaryText}
              </SecondaryTextStyled>
            }
          />
        </ListItem>
        <ListItem
          sx={{
            width: 'auto',
            cursor: 'pointer',
          }}
          onClick={handlePriorityButtonClick}
        >
          <ListItemText
            primary={
              <CurrentTaskCount>{userData?.count ?? 0}</CurrentTaskCount>
            }
          />
        </ListItem>

        <Divider component="li" />
      </ListItemContainer>
    </StyledList>
  );
};

export default CustomUserDataCard;
