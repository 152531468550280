import FormDialog from '@components/dialog/form-dialog/FormDialog';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateUserActivityStatus } from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import { APP } from 'src/styles/variables';
import { SubTextContainer } from './MarkAsActiveOrInactiveUserConfirmationDialogStyle';
import { useNavigate } from 'react-router-dom';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

export interface IMarkAsActiveOrInactiveUserConfirmationDialogProps {
  userId: string;
  isUserActive: boolean;
  isDialogOpen: boolean;
  onClose: () => void;
  onSubmitCloseDialog: () => void;
}

const MarkAsActiveOrInactiveUserConfirmationDialog = (
  markAsActiveOrInactiveUserConfirmationDialogProps: IMarkAsActiveOrInactiveUserConfirmationDialogProps,
): ReactElement => {
  const {
    userId = '',
    isUserActive = false,
    isDialogOpen,
    onClose,
    onSubmitCloseDialog,
  } = markAsActiveOrInactiveUserConfirmationDialogProps;

  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit } = useForm({});

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const payloadData = {
        isActive: !isUserActive,
      };

      const updateUserActivityStatusApiResponse =
        await updateUserActivityStatus(userId, payloadData);
      enqueueSnackbar(
        updateUserActivityStatusApiResponse.data.message,
        SnackBarConfig.getSuccess(),
      );
      onSubmitCloseDialog();
      navigate(PrivateNavigationRoutes.Admins.ChildRoutes.Users.FullPath, {
        state: {
          updatedUserSettings: true,
          userActivityStatus: isUserActive
            ? AdminConstants.USERS.INACTIVE
            : AdminConstants.USERS.ACTIVE,
        },
      });
    } catch (errorResponse: any) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setIsLoading(false);
    }
  };

  const renderDialogContent = () => {
    return (
      <SubTextContainer>
        {t('ADMIN.USERS_TAB.MARK_USER_AS_SUBTEXT')}{' '}
        {isUserActive
          ? t('ADMIN.USERS_TAB.INACTIVE')
          : t('ADMIN.USERS_TAB.ACTIVE')}
      </SubTextContainer>
    );
  };

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onClose}
      titleText={
        isUserActive
          ? t('ADMIN.USERS_TAB.MARK_USER_AS_INACTIVE')
          : t('ADMIN.USERS_TAB.MARK_USER_AS_ACTIVE')
      }
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText=""
      dialogContent={renderDialogContent()}
      submitButtonText={t('ADMIN.USERS_TAB.YES')}
      submitButtonLoadingText={t('ADMIN.USERS_TAB.SAVING')}
      cancelButtonText={t('ADMIN.USERS_TAB.NO')}
      onSubmit={handleSubmit(onSubmit)}
      isSaveInProgress={isLoading}
      disableSubmitButton={isLoading}
    />
  );
};

export default MarkAsActiveOrInactiveUserConfirmationDialog;
